import clsx from 'clsx';
import * as api from 'ego-sdk-js';
import { m } from 'framer-motion';
import React from 'react';
import { thumbHashToDataURL } from 'thumbhash';

import { base64ToByteArray } from '../util';

import ExpandIcon from './icon/ExpandIcon';
import { useKeyPress } from './KeyPressContext';
import Button from './lib/Button';
import Modal from './lib/Modal';
import { useModalManager } from './ModalManagerContext';

/**
 * WARNING: ImageWithViewer returns a series of top-level components rather
 * than a wrapper in order to behave correctly with the dynamic
 * sibling-content-based height required by prexos. Because of this, it's a
 * RARE exception to the rule of self-contained component styles, as it
 * requires an outer-component to specify its tailwind group (tw-group/img)
 * for hover effects.
 */
export const ImageWithViewer = (props: { src: string; imageInfo?: api.feed.IContentImageSpec; className?: string }) => {
  const { pushModal } = useModalManager();

  const [loaded, setLoaded] = React.useState(false);
  // Special-case handling for when this is a 1x1 tracking pixel.
  // NOTE: Consider removing the pixel entirely.
  const [isPixel, setIsPixel] = React.useState(false);

  const [thumbhashDataUrl, setThumbhashDataUrl] = React.useState<string | null>(null);
  React.useEffect(() => {
    if (props.imageInfo && props.imageInfo.th) {
      setThumbhashDataUrl(thumbHashToDataURL(base64ToByteArray(props.imageInfo.th)));
    }
  }, [props.imageInfo]);
  return (
    <>
      {thumbhashDataUrl && !loaded ? (
        <m.img
          className="tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full"
          src={thumbhashDataUrl}
          initial={{ opacity: 1 }}
          animate={{ opacity: loaded ? 0 : 1 }}
          transition={{ duration: 0.2 }}
        />
      ) : null}
      <m.img
        src={props.src}
        width={props.imageInfo?.width}
        height={props.imageInfo?.height}
        className={clsx(props.className)}
        initial={{ opacity: 0 }}
        animate={{ opacity: loaded ? 1 : 0 }}
        transition={{ duration: 0.2 }}
        loading="lazy"
        onLoad={e => {
          if (e.currentTarget.naturalHeight === 1 && e.currentTarget.naturalWidth === 1) {
            setIsPixel(true);
          }
          setLoaded(true);
        }}
        // Keep tracking pixels miniature.
        style={
          isPixel
            ? {
                height: 1,
                width: 1,
              }
            : undefined
        }
      />
      {props.imageInfo ? (
        <div className="tw-absolute tw-bottom-2 tw-right-6 tw-z-10 tw-invisible group-hover/img:tw-visible">
          <Button
            variant="secondary"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              pushModal({
                component: modalProps => <ImageViewerModal {...modalProps} image={props.imageInfo!} />,
                dupKey: 'cpc-editor',
                kind: 'generic',
              });
            }}
          >
            <ExpandIcon size="1rem" />
          </Button>
        </div>
      ) : null}
    </>
  );
};

export default ImageWithViewer;

export const ImageViewerModal = (props: { close: () => void; isForeground: boolean; image: api.feed.IImage }) => {
  const [loaded, setLoaded] = React.useState(false);
  const [thumbhashDataUrl, setThumbhashDataUrl] = React.useState<string | null>(null);
  React.useEffect(() => {
    if (props.image && props.image.th) {
      setThumbhashDataUrl(thumbHashToDataURL(base64ToByteArray(props.image.th)));
    }
  }, [props.image]);

  const [expandedHeight, setExpandedHeight] = React.useState(false);
  const divRef = React.useRef<HTMLDivElement | null>(null);

  useKeyPress(
    ['u', 'Escape'],
    () => {
      props.close();
    },
    false,
    10,
  );

  const clickWillZoom = !expandedHeight && !!divRef.current && divRef.current.offsetHeight < props.image.height;

  return (
    <Modal.Container
      show={props.isForeground}
      close={props.close}
      enableLgMediaSize
      enableFullWidth
      enableScreenHeight={!expandedHeight}
    >
      <Modal.Body zeroPy className="tw-w-full tw-h-full">
        <div
          ref={divRef}
          role="button"
          className={clsx('tw-w-full tw-h-full', clickWillZoom ? 'tw-cursor-zoom-in' : null)}
          onClick={() => {
            if (!expandedHeight && divRef.current && divRef.current.offsetHeight < props.image.height) {
              setExpandedHeight(true);
            } else {
              props.close();
            }
          }}
        >
          {thumbhashDataUrl ? (
            <img className="tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full" src={thumbhashDataUrl} />
          ) : null}
          <m.img
            src={props.image.url}
            width={props.image.width}
            height={props.image.height}
            className="tw-relative tw-w-full tw-h-full tw-object-contain tw-z-10"
            onLoad={() => setLoaded(true)}
            initial={{ opacity: 0 }}
            animate={{ opacity: loaded ? 1 : 0 }}
            transition={{ duration: 0.2 }}
          />
        </div>
      </Modal.Body>
    </Modal.Container>
  );
};
