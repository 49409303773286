import clsx from 'clsx';
import { AnimatePresence, m } from 'framer-motion';
import React from 'react';

import BackIcon from '../icon/BackIcon';
import CloseIcon from '../icon/CloseIcon';

let modalCount: number = 0;

export type ModalAnimate = 'slide-from-top' | 'slide-from-bottom' | 'zoom';

export interface ModalProps {
  show: boolean;
  close: () => void;
  className?: string;
  backdropClassName?: string;
  containerClassName?: string;
  closeBtnClassName?: string;
  animate?: ModalAnimate;
  enableLgMediaSize?: boolean;
  enableFullWidth?: boolean;
  enableScreenHeight?: boolean;
  sidebar?: React.ReactNode;
  mobileHeaderRightComponent?: React.ReactNode;
  children?: React.ReactNode;
}

export const ModalContainer = React.forwardRef<HTMLDivElement, ModalProps>((props, parentRef) => {
  React.useEffect(() => {
    if (modalCount === 0) {
      document.body.classList.add('tw-overflow-hidden');
      // FIXME: This padding isn't right. There's a gap between no breakpoint
      // and sm breakpoint where the padding addition is necessary.
      document.body.classList.add('sm:tw-pr-[5px]');
    }
    modalCount += 1;
    return () => {
      modalCount -= 1;
      if (modalCount === 0) {
        document.body.classList.remove('tw-overflow-hidden');
        document.body.classList.remove('sm:tw-pr-[5px]');
      }
    };
  }, []);

  const localRef = React.useRef<HTMLDivElement | null>(null);
  React.useEffect(() => {
    if (localRef.current) {
      // Take focus so that scrolling via keyboard works.
      localRef.current.focus();
    }
  }, []);

  // TODO/FIXME: The modal does not mark the rest of the page's focus-able
  // elements as inert. One approach is to use the <dialog> tag and the browser
  // will automatically take care of inert. However, it automatically triggers
  // aria focus which makes for ugly UI.
  return (
    <>
      <m.div
        initial={props.animate ? { opacity: 0 } : undefined}
        animate={props.animate ? { opacity: 1 } : undefined}
        exit={props.animate ? { opacity: 0 } : undefined}
        transition={props.animate ? { duration: 0.15 } : undefined}
        className={clsx(
          'tw-fixed tw-top-0 tw-left-0',
          'tw-z-50',
          'tw-w-screen tw-h-screen',
          'tw-bg-black/40',
          props.backdropClassName,
        )}
      />
      <div
        role="button"
        className={clsx(
          'tw-hidden sm:tw-block tw-fixed tw-z-[52] tw-top-2 tw-right-2 tw-text-light',
          props.closeBtnClassName,
        )}
        onClick={e => {
          // If multiple modals are stacked, propagation will close them all.
          e.stopPropagation();
          props.close();
        }}
      >
        <CloseIcon size="2.5rem" />
      </div>
      <m.div
        ref={ref => {
          localRef.current = ref;
          if (typeof parentRef === 'function') {
            parentRef(ref);
          } else if (parentRef !== null) {
            // HACK: Typescript doesn't like this, but it works.
            // If it becomes burdensome, consider switching to useImperativeHandler.
            // @ts-ignore
            parentRef.current = ref;
          }
        }}
        role="dialog"
        aria-modal="true"
        // Enables focus on modal for scrolling via keyboard.
        tabIndex={-1}
        className={clsx(
          'modal-scrollbar', // Only for scrollbar styling
          props.show ? 'tw-fixed' : 'tw-hidden',
          'tw-z-[51]',
          'tw-top-0 tw-left-0',
          'tw-w-full tw-h-full',
          'tw-overflow-y-scroll tw-overflow-x-hidden',
          props.className,
        )}
        onClick={e => {
          // If multiple modals are stacked, propagation will close them all.
          e.stopPropagation();
          props.close();
        }}
      >
        {props.sidebar ? (
          <div
            className="tw-absolute tw-top-[10vh] tw-right-0 tw-max-w-[180px]"
            // Prevent propagation to backdrop
            onClick={e => e.stopPropagation()}
          >
            {props.sidebar}
          </div>
        ) : null}
        <m.div
          initial={
            props.animate
              ? {
                  opacity: 0,
                  scale: props.animate === 'zoom' ? 1.1 : undefined,
                  translateY:
                    props.animate === 'slide-from-top'
                      ? '-25vh'
                      : props.animate === 'slide-from-bottom'
                        ? '100vh'
                        : undefined,
                }
              : undefined
          }
          animate={
            props.animate
              ? {
                  opacity: 1,
                  scale: props.animate === 'zoom' ? 1 : undefined,
                  translateY:
                    props.animate === 'slide-from-top' || props.animate === 'slide-from-bottom' ? '0' : undefined,
                }
              : undefined
          }
          exit={
            props.animate
              ? {
                  opacity: 0,
                  scale: props.animate === 'zoom' ? 1.1 : undefined,
                  translateY:
                    props.animate === 'slide-from-top'
                      ? '-25vh'
                      : props.animate === 'slide-from-bottom'
                        ? '100vh'
                        : undefined,
                }
              : undefined
          }
          transition={props.animate ? { duration: 0.2 } : undefined}
          className={clsx(
            'tw-relative',
            'tw-container',
            'tw-max-w-[90%]',
            !props.enableFullWidth ? 'sm:tw-max-w-[560px] md:tw-max-w-[660px]' : 'tw-w-full',
            !props.enableFullWidth && props.enableLgMediaSize ? 'lg:tw-max-w-[860px]' : null,
            // For mobile-web, there's less visible space because there's a
            // tendency for the URL bar and bottom status bar to show.
            props.enableScreenHeight
              ? 'tw-h-[84vh] sm:tw-h-[90vh] tw-mt-[1vh] sm:tw-mt-[5vh] tw-overflow-y-hidden'
              : 'tw-my-[3rem]',
            'tw-mx-auto',
            'tw-border tw-border-solid tw-border-layout-line-light dark:tw-border-layout-line-dark tw-rounded-md tw-shadow-lg',
            // Reset text styles in case the modal is inheriting settings from parent.
            'tw-bg-primary tw-text-primary tw-text-base tw-font-normal',
            props.containerClassName,
          )}
          // If propagation isn't stopped, the backdrop click-to-close will fire
          onClick={e => e.stopPropagation()}
        >
          <div
            role="button"
            className={clsx(
              'tw-sticky tw-top-0 tw-z-50 tw-flex tw-items-center tw-justify-between sm:tw-hidden tw-p-2 tw-bg-primary tw-text-primary',
              'tw-border-solid tw-border-b-1 tw-border-x-0 tw-border-t-0 tw-border-layout-line-light dark:tw-border-layout-line-dark',
            )}
            onClick={props.close}
          >
            <span>
              <BackIcon size="1rem" className="-tw-top-[0.08em]" />
              Back
            </span>
            {props.mobileHeaderRightComponent}
          </div>
          {props.children}
        </m.div>
      </m.div>
    </>
  );
});

export const ModalPresence = (props: { children: React.ReactNode }) => (
  <AnimatePresence>{props.children}</AnimatePresence>
);

export const ModalHeader = (props: { className?: string; children: React.ReactNode }) => (
  <div
    className={clsx(
      'tw-bg-highlight tw-py-3 tw-px-5',
      'tw-border-solid tw-border tw-border-x-0 tw-border-t-0 tw-border-layout-line-light dark:tw-border-layout-line-dark tw-rounded-t-md',
      props.className,
    )}
  >
    {props.children}
  </div>
);

export const ModalBody = (props: {
  gutter?: boolean;
  zeroPy?: boolean;
  className?: string;
  children: React.ReactNode;
}) => (
  <div className={clsx(props.gutter ? modalGutterClassName : null, !props.zeroPy ? 'tw-py-3' : null, props.className)}>
    {props.children}
  </div>
);

export const modalGutterClassName = 'tw-px-4 sm:tw-px-6';

export const ModalGutter = (props: { children: React.ReactNode }) => (
  <div className={modalGutterClassName}>{props.children}</div>
);

// Keep the padding and margin versions synced
export const modalGutterWideClassName = 'tw-px-6 sm:tw-px-8 md:tw-px-10 lg:tw-px-12';
export const modalGutterWideMarginClassName = 'tw-mx-6 sm:tw-mx-8 md:tw-mx-10 lg:tw-mx-12';

export const ModalGutterWide = (props: { children: React.ReactNode }) => (
  <div className={modalGutterWideClassName}>{props.children}</div>
);

export const ModalHeading1 = (props: { className?: string; children: React.ReactNode }) => (
  <div className={clsx('tw-text-2xl tw-font-bold tw-mt-6 tw-mb-2', props.className)}>{props.children}</div>
);

export const ModalHeading2 = (props: { className?: string; children: React.ReactNode }) => (
  <div className={clsx('tw-text-xl tw-font-bold tw-mt-4 tw-mb-2', props.className)}>{props.children}</div>
);

export const ModalHeading3 = (props: { className?: string; children: React.ReactNode }) => (
  <div className={clsx('tw-text-lg tw-font-bold tw-mt-3 tw-mb-2', props.className)}>{props.children}</div>
);

export const ModalHeading4 = (props: { className?: string; children: React.ReactNode }) => (
  <div className={clsx('tw-text-lg tw-font-semibold tw-mb-2', props.className)}>{props.children}</div>
);

const Modal = {
  Body: ModalBody,
  Container: ModalContainer,
  Gutter: ModalGutter,
  GutterWide: ModalGutterWide,
  Header: ModalHeader,
  Heading1: ModalHeading1,
  Heading2: ModalHeading2,
  Heading3: ModalHeading3,
  Heading4: ModalHeading4,
  Presence: ModalPresence,
};

export default Modal;
