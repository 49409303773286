import * as api from 'ego-sdk-js';
import React from 'react';

import * as Config from '../../config';
import useLoggedInUserId from './useLoggedInUserId';

const statusCallback = (statusCode: number) => {
  if (statusCode === 401) {
    window.location.href = '/login?next=' + window.location.pathname;
  }
};

const useApiClient = () => {
  const loggedInUserId = useLoggedInUserId();
  let apiAuth: api.Auth;
  if (loggedInUserId) {
    apiAuth = { kind: api.AuthKind.Cookie, userId: loggedInUserId };
  } else {
    apiAuth = { kind: api.AuthKind.NoAuth };
  }
  return React.useMemo(
    () => new api.SuperegoClient(Config.apiHost, apiAuth, statusCallback),
    [Config.apiHost, loggedInUserId],
  );
};

export const useAuthedApiClient = () => {
  const loggedInUserId = useLoggedInUserId();
  if (!loggedInUserId) {
    throw Error('Expected user to be logged-in.');
  }
  return React.useMemo(
    () => new api.SuperegoClient(Config.apiHost, { kind: api.AuthKind.Cookie, userId: loggedInUserId }, statusCallback),
    [Config.apiHost, loggedInUserId],
  );
};

export const useNoAuthApiClient = (loggedInOk?: boolean) => {
  const loggedInUserId = useLoggedInUserId();
  if (!loggedInOk && loggedInUserId) {
    throw Error('Expected user to be logged-out.');
  }
  return React.useMemo(
    () => new api.SuperegoClient(Config.apiHost, { kind: api.AuthKind.NoAuth }, statusCallback),
    [Config.apiHost],
  );
};

/**
 * Special-case client where we may or may not have a session (cannot check due
 * to cookie being set to HttpOnly). Even if there is an active session, userId
 * isn't known. This should typically only be used as the first query made on
 * web to assess a user's login status.
 */
export const useSessionDefaultApiClient = () => {
  return React.useMemo(
    () =>
      new api.SuperegoClient(Config.apiHost, { kind: api.AuthKind.Cookie, userId: 'session-default' }, statusCallback),
    [Config.apiHost],
  );
};

/**
 * Special-case client that's unauthenticated BUT accepts Set-Cookie headers in
 * the response. This may not seem like it would need a special case but it
 * requires the client to set credentials="include" even though it is not
 * including any credentials in the request.
 */
export const useNoAuthAcceptCookieApiClient = () => {
  return React.useMemo(
    () => new api.SuperegoClient(Config.apiHost, { kind: api.AuthKind.AcceptCookie }, statusCallback),
    [Config.apiHost],
  );
};

export default useApiClient;
