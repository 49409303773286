import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import { createActionCreators, ImmerReducer } from 'immer-reducer';

import { IAppState, IToast } from './store';
import * as store from './store';

interface ThreadRootCacheLoc {
  kind: 'root';
  threadId: string;
}

interface ThreadSiblingCacheLoc {
  kind: 'siblings';
  threadId: string;
  siblingsCommentId: string;
}

interface ThreadRepliesCacheLoc {
  kind: 'replies';
  threadId: string;
  repliesCommentId: string;
}

export type ThreadCommentCacheLoc = ThreadRootCacheLoc | ThreadRepliesCacheLoc | ThreadSiblingCacheLoc;

export class MainReducer extends ImmerReducer<IAppState> {
  public addFeed(feed: api.feed.IFeedInfo) {
    this.draftState.feeds.set(feed.feed_id, feed);
    if (this.draftState.exploredItem?.feed.feed_id === feed.feed_id) {
      this.draftState.exploredItem.feed = feed;
    }
    if (
      this.draftState.page.feedPage.curFeed &&
      this.draftState.page.feedPage.curFeed.kind === 'feed' &&
      this.draftState.page.feedPage.curFeed.feed.feed_id === feed.feed_id
    ) {
      this.draftState.page.feedPage.curFeed.feed = feed;
    }
  }

  public updateFeed(feed: api.feed.IFeedInfo) {
    this.draftState.feeds.set(feed.feed_id, feed);
    if (this.draftState.exploredItem?.feed.feed_id === feed.feed_id) {
      this.draftState.exploredItem.feed = feed;
    }
    if (
      this.draftState.page.feedPage.curFeed &&
      this.draftState.page.feedPage.curFeed.kind === 'feed' &&
      this.draftState.page.feedPage.curFeed.feed.feed_id === feed.feed_id
    ) {
      this.draftState.page.feedPage.curFeed.feed = feed;
    }
  }

  //
  // Keyboard Controls
  //

  public setKeyboardControlsActive(value: boolean) {
    this.draftState.keyboardControlsActive = value;
  }

  //
  // Theme Mode
  //

  public setThemeMode(value: store.ThemeMode) {
    this.draftState.appearance.themeMode = value;
  }

  //
  // For web login status
  //
  public setLoginInfo(loginStatus: api.user.LoginStatus) {
    if (loginStatus['.tag'] === 'authed') {
      this.draftState.apiCache.meInfo = cfe.ApiHook.mkCacheUnit({ kind: 'loaded', data: loginStatus.me_info });
      this.draftState.loginInfo = {
        kind: 'authed',
        userId: loginStatus.me_info.user_id,
      };
    } else if (loginStatus['.tag'] === 'assumed') {
      this.draftState.apiCache.meInfo = cfe.ApiHook.mkCacheUnit({ kind: 'loaded', data: loginStatus.me_info });
      this.draftState.loginInfo = {
        kind: 'assumed',
        userId: loginStatus.me_info.user_id,
      };
    } else if (loginStatus['.tag'] === 'noauth') {
      this.draftState.loginInfo = {
        kind: 'noauth',
      };
    } else {
      throw Error(`Unexpected loginStatus: ${loginStatus}`);
    }
  }

  //
  // API Cache
  //

  public apiCacheSetMeInfo(value: cfe.ApiHook.CacheUnit<cfe.ApiData.Data<api.user.IMeInfo>>) {
    this.draftState.apiCache.meInfo = value;
  }

  public apiCacheSetAgentTopicTaxonomy(
    value: cfe.ApiHook.CacheUnit<cfe.ApiData.Data<api.feed.IGetAgentTopicTaxonomyResult>>,
  ) {
    this.draftState.apiCache.agentTopicTaxonomy = value;
  }

  public apiCacheSetFeedEntryStandalone(
    entryId: string,
    value: cfe.ApiHook.CacheUnit<cfe.ApiData.Data<api.feed.IEntryGetResult, api.feed.EntryGetError>>,
  ) {
    if (this.draftState.apiCache.feedEntryStandalone.size > 100) {
      this.apiCacheEvictByAge(this.draftState.apiCache.feedEntryStandalone, 10);
    }
    this.draftState.apiCache.feedEntryStandalone.set(entryId, value);
  }

  public apiCacheOverrideFeedEntryStandalone(value: api.feed.IEntryGetResult) {
    if (this.draftState.apiCache.feedEntryStandalone.size > 100) {
      this.apiCacheEvictByAge(this.draftState.apiCache.feedEntryStandalone, 10);
    }
    this.draftState.apiCache.feedEntryStandalone.set(value.entry.entry_id, {
      ts: Date.now(),
      value: { kind: 'loaded', data: value },
    });
    this.updateFeed(value.feed);
    // NOTE: Important that this doesn't circularly call this function back.
    this.updateFeedEntry(value.feed.feed_id, value.entry);
  }

  public apiCacheSetFeedEntries(
    feedId: string,
    argKey: string,
    value: cfe.ApiHook.CacheUnit<cfe.ApiData.Data<api.feed.IEntryIterResult[]>>,
  ) {
    this.draftState.apiCache.feedEntries.set(argKey, value);
    const feedEntryKeys = this.draftState.apiCache.feedEntryKeysByFeedId.get(feedId);
    if (feedEntryKeys === undefined) {
      this.draftState.apiCache.feedEntryKeysByFeedId.set(feedId, [argKey]);
    } else {
      if (feedEntryKeys.indexOf(argKey) === -1) {
        feedEntryKeys.push(argKey);
      }
    }
  }

  public apiCacheSetInboxEntries(value: cfe.ApiHook.CacheUnit<cfe.ApiData.Data<api.newsfeed.IEntryIterResult[]>>) {
    this.draftState.apiCache.inboxEntries = value;
  }

  public apiCacheSetInboxUnread(value: cfe.ApiHook.CacheUnit<cfe.ApiData.Data<api.inbox.IGetUnreadResult>>) {
    this.draftState.apiCache.inboxUnread = value;
  }

  public apiCacheSetNewsfeedEntries(value: cfe.ApiHook.CacheUnit<cfe.ApiData.Data<api.newsfeed.IEntryIterResult[]>>) {
    this.draftState.apiCache.newsfeedEntries = value;
  }

  public apiCacheSetNewsfeedAsUserEntries(
    userId: string,
    value: cfe.ApiHook.CacheUnit<cfe.ApiData.Data<api.newsfeed.IEntryIterResult[]>>,
  ) {
    this.draftState.apiCache.newsfeedAsUserEntries = [userId, value];
  }

  public apiCacheClearNewsfeedEntries() {
    this.draftState.apiCache.newsfeedEntries = cfe.ApiHook.getCacheEmptySingleton();
  }

  public apiCacheSetStimCommentary(
    key: string,
    value: cfe.ApiHook.CacheUnit<cfe.ApiData.Data<api.stimulus.IGetCommentaryResult>>,
  ) {
    if (this.draftState.apiCache.stimCommentary.size > 30) {
      this.apiCacheEvictByAge(this.draftState.apiCache.stimCommentary, 5);
    }
    this.draftState.apiCache.stimCommentary.set(key, value);
  }

  public apiCacheSetStimGet(key: string, value: cfe.ApiHook.CacheUnit<cfe.ApiData.Data<api.stimulus.IGetResult>>) {
    if (this.draftState.apiCache.stimGet.size > 30) {
      this.apiCacheEvictByAge(this.draftState.apiCache.stimGet, 5);
    }
    this.draftState.apiCache.stimGet.set(key, value);
  }

  public apiCacheSetStimRss(key: string, value: cfe.ApiHook.CacheUnit<cfe.ApiData.Data<api.stimulus.IGetRssResult>>) {
    if (this.draftState.apiCache.stimRss.size > 30) {
      this.apiCacheEvictByAge(this.draftState.apiCache.stimRss, 5);
    }
    this.draftState.apiCache.stimRss.set(key, value);
  }

  public apiCacheClearStimCascade(entry: api.feed.IFeedEntryReference) {
    if (entry['.tag'] === 'share') {
      this.apiCachePurgeThread(entry.ffa_thread_id);
    }
    const url = entry.strong_ref?.url ?? entry.url;
    const stimGetRes = this.draftState.apiCache.stimGet.get(url);
    if (stimGetRes) {
      const stimGetData = cfe.ApiData.getData(stimGetRes.value);
      if (stimGetData) {
        if (stimGetData.qna_thread_id) {
          this.apiCachePurgeThread(stimGetData.qna_thread_id);
        }
        if (stimGetData.trace_thread_id) {
          this.apiCachePurgeThread(stimGetData.trace_thread_id);
        }
      }
      this.draftState.apiCache.stimGet.delete(url);
    }
    this.draftState.apiCache.stimRss.delete(url);
    this.draftState.apiCache.stimCommentary.delete(url);
  }

  // ----

  public apiCacheSetThreadRoot(key: string, value: cfe.ApiHook.CacheUnit<cfe.ApiData.Data<api.thread.IGetTreeResult>>) {
    if (this.draftState.apiCache.threadRoot.size > 30) {
      const threadIds = this.apiCacheEvictByAge(this.draftState.apiCache.threadRoot, 5);
      for (const threadId of threadIds) {
        this.draftState.apiCache.threadSiblings.delete(threadId);
        this.draftState.apiCache.threadReplies.delete(threadId);
      }
    }
    this.draftState.apiCache.threadRoot.set(key, value);
  }

  public apiCachePurgeThread(key: string) {
    this.draftState.apiCache.threadSiblings.delete(key);
    this.draftState.apiCache.threadReplies.delete(key);
    this.draftState.apiCache.threadRoot.delete(key);
  }

  public apiCacheSetThreadSiblings(
    key: string,
    commentId: string,
    value: cfe.ApiHook.CacheUnit<cfe.ApiData.Data<api.thread.IGetSiblingsResult>>,
  ) {
    if (this.draftState.apiCache.threadSiblings.has(key)) {
      this.draftState.apiCache.threadSiblings.get(key)!.set(commentId, value);
    } else {
      this.draftState.apiCache.threadSiblings.set(key, new Map([[commentId, value]]));
    }
  }

  public apiCacheSetThreadReplies(
    key: string,
    commentId: string,
    value: cfe.ApiHook.CacheUnit<cfe.ApiData.Data<api.thread.IGetRepliesResult>>,
  ) {
    if (this.draftState.apiCache.threadReplies.has(key)) {
      this.draftState.apiCache.threadReplies.get(key)!.set(commentId, value);
    } else {
      this.draftState.apiCache.threadReplies.set(key, new Map([[commentId, value]]));
    }
  }

  /**
   * NOTE: `threadId` is specified separately from `threadInfo.threadId`
   * because it's possible for the threadId to be a placeholder ID whereas the
   * threadInfo contains the reified threadId.
   *
   * If the thread started with a placeholder ID, its critical that the ID is
   * persistently used. A (partial) switch to the reified threadId will create
   * logical inconsistencies.
   */
  public apiCacheThreadInfoUpdate(threadId: string, threadInfo: api.thread.IThreadInfo) {
    const threadRoot = this.draftState.apiCache.threadRoot.get(threadId);
    if (threadRoot && cfe.ApiData.hasData(threadRoot.value)) {
      threadRoot.ts = Date.now();
      threadRoot.value.data.thread = threadInfo;
    }
  }

  public apiCacheThreadCommentAddToRoot(threadId: string, newComment: api.thread.IComment) {
    const root = this.draftState.apiCache.threadRoot.get(threadId);
    if (!root || !cfe.ApiData.hasData(root.value)) {
      return;
    }
    root.value.data.root_comment_count += 1;
    root.value.data.root_comments.unshift(newComment);
  }

  public apiCacheThreadCommentUpdate(loc: ThreadCommentCacheLoc, updatedComment: api.thread.IComment) {
    let comments: api.thread.IComment[][];
    if (loc.kind === 'root') {
      const root = this.draftState.apiCache.threadRoot.get(loc.threadId);
      if (!root || !cfe.ApiData.hasData(root.value)) {
        return;
      }
      comments = [root.value.data.root_comments];
    } else if (loc.kind === 'replies') {
      const threadReplies = this.draftState.apiCache.threadReplies.get(loc.threadId);
      if (!threadReplies) {
        return;
      }
      const repliesResult = threadReplies.get(loc.repliesCommentId);
      if (!repliesResult || !cfe.ApiData.hasData(repliesResult.value)) {
        return;
      }
      comments = [repliesResult.value.data.top.replies];
    } else if (loc.kind === 'siblings') {
      const threadSiblings = this.draftState.apiCache.threadSiblings.get(loc.threadId);
      if (!threadSiblings) {
        return;
      }
      const siblingsResult = threadSiblings.get(loc.siblingsCommentId);
      if (!siblingsResult || !cfe.ApiData.hasData(siblingsResult.value)) {
        return;
      }
      comments = [siblingsResult.value.data.siblings];
    } else {
      throw Error('Unexpected');
    }
    const stack = [...comments];
    while (true) {
      const commentLevel = stack.pop();
      if (!commentLevel) {
        // Couldn't find comment. Race condition?
        break;
      }
      for (const [index, comment] of commentLevel.entries()) {
        if (comment.comment_id === updatedComment.comment_id) {
          commentLevel.splice(index, 1, updatedComment);
          break;
        }
        stack.push(comment.replies);
      }
    }
  }

  public apiCacheThreadCommentMutate(
    loc: ThreadCommentCacheLoc,
    commentId: string,
    mutateFn: (comment: api.thread.IComment) => void,
  ) {
    let comments: api.thread.IComment[][];
    if (loc.kind === 'root') {
      const root = this.draftState.apiCache.threadRoot.get(loc.threadId);
      if (!root || !cfe.ApiData.hasData(root.value)) {
        return;
      }
      comments = [root.value.data.root_comments];
    } else if (loc.kind === 'replies') {
      const threadReplies = this.draftState.apiCache.threadReplies.get(loc.threadId);
      if (!threadReplies) {
        return;
      }
      const repliesResult = threadReplies.get(loc.repliesCommentId);
      if (!repliesResult || !cfe.ApiData.hasData(repliesResult.value)) {
        return;
      }
      if (loc.repliesCommentId === commentId) {
        // IMPORTANT: A comment that's been queried for replies has effectively
        // two spots in the store that we need to keep in-sync.
        mutateFn(repliesResult.value.data.top);
      }
      comments = [repliesResult.value.data.top.replies];
    } else if (loc.kind === 'siblings') {
      const threadSiblings = this.draftState.apiCache.threadSiblings.get(loc.threadId);
      if (!threadSiblings) {
        return;
      }
      const siblingsResult = threadSiblings.get(loc.siblingsCommentId);
      if (!siblingsResult || !cfe.ApiData.hasData(siblingsResult.value)) {
        return;
      }
      comments = [siblingsResult.value.data.siblings];
    } else {
      throw Error('Unexpected');
    }
    const stack = [...comments];
    while (true) {
      const commentLevel = stack.pop();
      if (!commentLevel) {
        // Couldn't find comment. Race condition?
        break;
      }
      for (const comment of commentLevel) {
        if (comment.comment_id === commentId) {
          mutateFn(comment);
          break;
        }
        stack.push(comment.replies);
      }
    }
  }

  // ----

  public apiCacheEvictByAge<T, E>(
    cache: Map<string, cfe.ApiHook.CacheUnit<cfe.ApiData.Data<T, E>>>,
    ageThresholdInMins: number,
  ) {
    const now = Date.now();
    const expiredKeys = [];
    for (const [key, cacheUnit] of cache.entries()) {
      if (now - cacheUnit.ts > 1000 * 60 * ageThresholdInMins) {
        expiredKeys.push(key);
      }
    }
    for (const expiredKey of expiredKeys) {
      cache.delete(expiredKey);
    }
    return expiredKeys;
  }

  //
  // Explored Item
  //

  public setExploredItem(item: store.ExploredItem) {
    this.draftState.exploredItem = item;
  }

  /**
   * NOTE: This does not change URL parameters.
   */
  public popExploredItem() {
    this.draftState.exploredItem = null;
  }

  //
  // Feed Page
  //

  public setFeedPageCurFeed(curFeed?: store.CurFeed) {
    this.draftState.page.feedPage.curFeed = curFeed;
  }

  public setForceInboxRefresh(val: boolean) {
    this.draftState.forceInboxRefresh = val;
  }

  //
  // User feeds
  //

  public apiFeedsSwapSet(userId: string, feedsList: cfe.ApiData.Data<api.feed.IFeedInfo[]>) {
    if (cfe.ApiData.hasData(feedsList)) {
      feedsList.data.map(feed => this.draftState.feeds.set(feed.feed_id, feed));
    }
    const existing = this.draftState.feedsByUser.get(userId);
    if (existing && cfe.ApiData.hasData(existing) && !cfe.ApiData.isError(existing) && cfe.ApiData.isError(feedsList)) {
      // Don't swap a good feed list with an error-ed one.
      return;
    }
    if (feedsList.kind === 'loaded' || feedsList.kind === 'error-data') {
      this.draftState.feedsByUser.set(userId, {
        data: feedsList.data.map(feed => feed.feed_id),
        kind: feedsList.kind,
      });
    } else if (feedsList.kind === 'error') {
      this.draftState.feedsByUser.set(userId, feedsList);
    } else {
      throw Error(`Unexpected feedsList kind: ${feedsList.kind}`);
    }
  }

  public apiFeedsLoadFirst(userId: string, feedIterResult: api.feed.IFeedIterResult) {
    const feedsForUser = this.draftState.feedsByUser.get(userId)!;
    if (cfe.ApiData.isLoading(feedsForUser)) {
      const feedIdsForUser = [];
      for (const feed of feedIterResult.feeds) {
        feedIdsForUser.push(feed.feed_id);
      }
      if (feedIterResult.has_more) {
        this.draftState.feedsByUser.set(userId, { kind: 'loading-data', data: feedIdsForUser });
      } else {
        this.draftState.feedsByUser.set(userId, { kind: 'loaded', data: feedIdsForUser });
      }
    }
    feedIterResult.feeds.map(feed => this.updateFeed(feed));
  }

  public apiFeedsLoad(userId: string, feedIterResult: api.feed.IFeedIterResult) {
    const feedsForUser = this.draftState.feedsByUser.get(userId)!;
    if (feedsForUser.kind === 'loading-data') {
      const feedIdsForUser = [];
      for (const feed of feedIterResult.feeds) {
        feedIdsForUser.push(feed.feed_id);
      }
      if (feedIterResult.has_more) {
        this.draftState.feedsByUser.set(userId, {
          data: feedsForUser.data.concat(feedIdsForUser),
          kind: 'loading-data',
        });
      } else {
        this.draftState.feedsByUser.set(userId, { kind: 'loaded', data: feedsForUser.data.concat(feedIdsForUser) });
      }
    }
    feedIterResult.feeds.map(feed => this.updateFeed(feed));
  }

  public apiFeedsLoading(userId: string) {
    const feedsForUser = this.draftState.feedsByUser.get(userId);
    if (!feedsForUser || !cfe.ApiData.hasData(feedsForUser)) {
      this.draftState.feedsByUser.set(userId, { kind: 'loading' });
    } else {
      this.draftState.feedsByUser.set(userId, {
        data: feedsForUser.data,
        kind: 'loading-data',
      });
    }
  }

  public apiFeedsError(userId: string) {
    const feedsForUser = this.draftState.feedsByUser.get(userId)!;
    if (feedsForUser.kind === 'loading') {
      this.draftState.feedsByUser.set(userId, {
        kind: 'error',
      });
    } else if (feedsForUser.kind === 'loading-data') {
      this.draftState.feedsByUser.set(userId, {
        data: feedsForUser.data,
        kind: 'error-data',
      });
    }
  }

  public apiFeedsClearByUser(userId: string) {
    const feedsForUser = this.draftState.feedsByUser.get(userId);
    if (feedsForUser && feedsForUser.kind === 'loaded') {
      this.draftState.feedsByUser.set(userId, {
        kind: 'unknown',
      });
    }
  }

  // ---

  public prependUserFeed(userId: string, newFeed: api.feed.IFeedInfo) {
    const feedsForUser = this.draftState.feedsByUser.get(userId);
    if (feedsForUser && feedsForUser.kind === 'loaded') {
      this.draftState.feeds.set(newFeed.feed_id, newFeed);
      feedsForUser.data.unshift(newFeed.feed_id);
    }
  }

  public unfollowFeed(userId: string, newFeed: api.feed.IFeedInfo, updateUserFeeds: boolean) {
    const feedsForUser = this.draftState.feedsByUser.get(userId);
    if (feedsForUser && feedsForUser.kind === 'loaded') {
      this.updateFeed(newFeed);
      if (updateUserFeeds && !newFeed.for_viewer.following && !newFeed.for_viewer.perm.read) {
        const index = feedsForUser.data.indexOf(newFeed.feed_id);
        if (index > -1) {
          feedsForUser.data.splice(index, 1);
        }
      }
    }
  }

  public followFeed(userId: string, newFeed: api.feed.IFeedInfo, updateUserFeeds: boolean) {
    const feedsForUser = this.draftState.feedsByUser.get(userId);
    if (feedsForUser && feedsForUser.kind === 'loaded') {
      this.updateFeed(newFeed);
      if (updateUserFeeds && newFeed.for_viewer.following) {
        const index = feedsForUser.data.indexOf(newFeed.feed_id);
        if (index === -1) {
          this.prependUserFeed(userId, newFeed);
        }
      }
    }
  }

  public changeUserFeedPos(userId: string, targetFeedId: string, afterFeedId?: string) {
    const feedsForUser = this.draftState.feedsByUser.get(userId);
    if (feedsForUser && feedsForUser.kind === 'loaded') {
      const oldPos = feedsForUser.data.indexOf(targetFeedId);
      let newPos;
      if (afterFeedId) {
        newPos = feedsForUser.data.indexOf(afterFeedId);
      } else {
        newPos = -1;
      }
      if (newPos < oldPos) {
        newPos = newPos + 1;
      }
      feedsForUser.data.splice(oldPos, 1);
      feedsForUser.data.splice(newPos, 0, targetFeedId);
    }
  }

  public removeFeed(userId: string, feed: api.feed.IFeedInfo) {
    const feedsForUser = this.draftState.feedsByUser.get(userId);
    if (feedsForUser && feedsForUser.kind === 'loaded') {
      const index = feedsForUser.data.indexOf(feed.feed_id);
      if (index > -1) {
        feedsForUser.data.splice(index, 1);
      }
      this.draftState.feeds.delete(feed.feed_id);
    }
  }

  public removePluggedNewsfeedEntry(feedId: string) {
    const newsfeedEntries = this.draftState.apiCache.newsfeedEntries;
    if (cfe.ApiData.hasData(newsfeedEntries.value)) {
      for (const iterResult of newsfeedEntries.value.data) {
        for (let i = 0; i < iterResult.entries.length; i++) {
          const nfEntry = iterResult.entries[i];
          if (nfEntry.plugged && nfEntry.feed.feed_id === feedId) {
            iterResult.entries.splice(i, 1);
            break;
          }
        }
      }
    }
    const newsfeedAsUserEntries = this.draftState.apiCache.newsfeedAsUserEntries[1];
    if (cfe.ApiData.hasData(newsfeedAsUserEntries.value)) {
      for (const iterResult of newsfeedAsUserEntries.value.data) {
        for (let i = 0; i < iterResult.entries.length; i++) {
          const nfEntry = iterResult.entries[i];
          if (nfEntry.plugged && nfEntry.feed.feed_id === feedId) {
            iterResult.entries.splice(i, 1);
            break;
          }
        }
      }
    }
  }

  public removeNewsfeedFeedEmbed(feedId: string) {
    const newsfeedEntries = this.draftState.apiCache.newsfeedEntries;
    if (cfe.ApiData.hasData(newsfeedEntries.value)) {
      for (const iterResult of newsfeedEntries.value.data) {
        for (let i = 0; i < iterResult.embeds.length; i++) {
          const feedEmbed = iterResult.embeds[i];
          if (feedEmbed.feed.feed_id === feedId) {
            iterResult.embeds.splice(i, 1);
            break;
          }
        }
      }
    }
    const newsfeedAsUserEntries = this.draftState.apiCache.newsfeedAsUserEntries[1];
    if (cfe.ApiData.hasData(newsfeedAsUserEntries.value)) {
      for (const iterResult of newsfeedAsUserEntries.value.data) {
        for (let i = 0; i < iterResult.embeds.length; i++) {
          const feedEmbed = iterResult.embeds[i];
          if (feedEmbed.feed.feed_id === feedId) {
            iterResult.embeds.splice(i, 1);
            break;
          }
        }
      }
    }
  }

  public prependFeedEntry(feedId: string, entry: api.feed.IFeedEntryReference) {
    if (entry.via) {
      this.updateFeed(entry.via.feed);
    }
    if (entry.natural_topic) {
      this.updateFeed(entry.natural_topic);
    }
    if (this.draftState.exploredItem?.entry.entry_id === entry.entry_id) {
      // Passes by duck-typing, but fragile.
      this.draftState.exploredItem.entry = entry;
    }
    const feed = this.draftState.feeds.get(feedId);
    if (!feed) {
      return;
    }
    for (const argKey of this.draftState.apiCache.feedEntryKeysByFeedId.get(feedId) ?? []) {
      const cachedEntries = this.draftState.apiCache.feedEntries.get(argKey);
      if (cachedEntries !== undefined && cfe.ApiData.hasData(cachedEntries.value)) {
        cachedEntries.value.data[0].entries.splice(0, 0, entry);
      }
    }
  }

  public updateFeedEntry(feedId: string, updatedEntry: api.feed.IFeedEntryReference) {
    if (updatedEntry.via) {
      this.updateFeed(updatedEntry.via.feed);
    }
    if (updatedEntry.natural_topic) {
      this.updateFeed(updatedEntry.natural_topic);
    }
    if (this.draftState.exploredItem?.entry.entry_id === updatedEntry.entry_id) {
      // Passes by duck-typing, but fragile.
      this.draftState.exploredItem.entry = updatedEntry;
    }
    if (this.draftState.apiCache.feedEntryStandalone.has(updatedEntry.entry_id)) {
      const standaloneEntryRes = this.draftState.apiCache.feedEntryStandalone.get(updatedEntry.entry_id)!;
      if (cfe.ApiData.hasData(standaloneEntryRes.value) && standaloneEntryRes.value.data.entry !== updatedEntry) {
        // Check that the entry has changed to avoid circular state updates.
        standaloneEntryRes.value.data.entry = updatedEntry;
      }
    }
    if (feedId !== 'newsfeed') {
      for (const argKey of this.draftState.apiCache.feedEntryKeysByFeedId.get(feedId) ?? []) {
        const cachedEntries = this.draftState.apiCache.feedEntries.get(argKey);
        if (cachedEntries !== undefined && cfe.ApiData.hasData(cachedEntries.value)) {
          for (const iterRes of cachedEntries.value.data) {
            for (const [index, entry] of iterRes.entries.entries()) {
              if (entry.entry_id === updatedEntry.entry_id) {
                iterRes.entries.splice(index, 1, updatedEntry);
                break;
              }
            }
          }
        }
      }
    }

    const cachedNfEntries = this.draftState.apiCache.newsfeedEntries;
    if (cfe.ApiData.hasData(cachedNfEntries.value)) {
      for (const iterRes of cachedNfEntries.value.data) {
        for (const nfEntry of iterRes.entries) {
          if (nfEntry.entry.entry_id === updatedEntry.entry_id) {
            nfEntry.entry = updatedEntry;
            break;
          }
        }
      }
    }
    const cachedNfAsUserEntries = this.draftState.apiCache.newsfeedAsUserEntries[1];
    if (cfe.ApiData.hasData(cachedNfAsUserEntries.value)) {
      for (const iterRes of cachedNfAsUserEntries.value.data) {
        for (const nfEntry of iterRes.entries) {
          if (nfEntry.entry.entry_id === updatedEntry.entry_id) {
            nfEntry.entry = updatedEntry;
            break;
          }
        }
      }
    }
  }

  public updateFeedEntryProgress(feedId: string, entryId: string, progress: number) {
    this.mutateFeedEntry(feedId, entryId, entry => {
      if (entry.entry_id === entryId && entry.for_viewer.last_visit) {
        entry.for_viewer.last_visit.progress = progress;
      }
    });
  }

  public removeFeedEntry(feedId: string, entryToRemove: api.feed.IFeedEntryReference) {
    for (const argKey of this.draftState.apiCache.feedEntryKeysByFeedId.get(feedId) ?? []) {
      const cachedEntries = this.draftState.apiCache.feedEntries.get(argKey);
      if (cachedEntries !== undefined && cfe.ApiData.hasData(cachedEntries.value)) {
        for (const iterRes of cachedEntries.value.data) {
          for (const [index, entry] of iterRes.entries.entries()) {
            if (entry.entry_id === entryToRemove.entry_id) {
              iterRes.entries.splice(index, 1);
              break;
            }
          }
        }
      }
    }
    const cachedNfEntries = this.draftState.apiCache.newsfeedEntries;
    if (cfe.ApiData.hasData(cachedNfEntries.value)) {
      for (const iterRes of cachedNfEntries.value.data) {
        for (const [index, nfEntry] of iterRes.entries.entries()) {
          if (nfEntry.entry.entry_id === entryToRemove.entry_id) {
            iterRes.entries.splice(index, 1);
            break;
          }
        }
      }
    }
    const cachedNfAsUserEntries = this.draftState.apiCache.newsfeedAsUserEntries[1];
    if (cfe.ApiData.hasData(cachedNfAsUserEntries.value)) {
      for (const iterRes of cachedNfAsUserEntries.value.data) {
        for (const [index, nfEntry] of iterRes.entries.entries()) {
          if (nfEntry.entry.entry_id === entryToRemove.entry_id) {
            iterRes.entries.splice(index, 1);
            break;
          }
        }
      }
    }
    const cachedInboxEntries = this.draftState.apiCache.inboxEntries;
    if (cfe.ApiData.hasData(cachedInboxEntries.value)) {
      for (const iterRes of cachedInboxEntries.value.data) {
        for (const [index, nfEntry] of iterRes.entries.entries()) {
          if (nfEntry.entry.entry_id === entryToRemove.entry_id) {
            iterRes.entries.splice(index, 1);
            break;
          }
        }
      }
    }
  }

  public markFeedEntryAsVisited(feedId: string, visitedPrimaryUrl: string) {
    this.mutateFeedEntryByUrl(feedId, visitedPrimaryUrl, entry => {
      if (!entry.for_viewer.last_visit) {
        entry.for_viewer.last_visit = { when: new Date().toISOString() };
      }
    });
  }

  public markFeedEntryAsSeen(feedId: string, entryId: string) {
    const now = new Date().toISOString();
    this.mutateFeedEntry(feedId, entryId, entry => {
      if (entry.for_viewer.seen) {
        entry.for_viewer.seen.unseen = false;
        entry.for_viewer.seen.seen_watermark = entry.for_viewer.seen.watermark;
        entry.for_viewer.seen.when = now;
      }
    });
  }

  public markFeedEntryAsArchivedProvisional(feedId: string, entryId: string) {
    this.mutateFeedEntry(feedId, entryId, entry => (entry.for_viewer.archived = { when: new Date().toISOString() }));
  }

  public markFeedEntryAsUnarchivedProvisional(feedId: string, entryId: string) {
    this.mutateFeedEntry(feedId, entryId, entry => (entry.for_viewer.archived = undefined));
  }

  public setFeedEntryEgoCount(feedId: string, entryId: string, egoCount: number) {
    this.mutateFeedEntry(feedId, entryId, entry => {
      if (entry.feed_ref_counts) {
        entry.feed_ref_counts.ego = egoCount;
      }
    });
  }

  public mutateFeedEntry(feedId: string, entryId: string, f: (nfEntry: api.feed.IFeedEntryReference) => void) {
    if (this.draftState.exploredItem?.entry.entry_id === entryId) {
      f(this.draftState.exploredItem.entry);
    }
    if (this.draftState.apiCache.feedEntryStandalone.has(entryId)) {
      const standaloneEntryRes = this.draftState.apiCache.feedEntryStandalone.get(entryId)!;
      if (cfe.ApiData.hasData(standaloneEntryRes.value)) {
        f(standaloneEntryRes.value.data.entry);
      }
    }
    if (feedId !== 'newsfeed') {
      for (const argKey of this.draftState.apiCache.feedEntryKeysByFeedId.get(feedId) ?? []) {
        const cachedEntries = this.draftState.apiCache.feedEntries.get(argKey);
        if (cachedEntries !== undefined && cfe.ApiData.hasData(cachedEntries.value)) {
          for (const iterRes of cachedEntries.value.data) {
            for (const entry of iterRes.entries) {
              if (entry.entry_id === entryId) {
                f(entry);
                break;
              }
            }
          }
        }
      }
    }
    const cachedNfEntries = this.draftState.apiCache.newsfeedEntries;
    if (cfe.ApiData.hasData(cachedNfEntries.value)) {
      for (const iterRes of cachedNfEntries.value.data) {
        for (const nfEntry of iterRes.entries) {
          if (nfEntry.entry.entry_id === entryId) {
            f(nfEntry.entry);
            break;
          }
        }
      }
    }
    const cachedNfAsUserEntries = this.draftState.apiCache.newsfeedAsUserEntries[1];
    if (cfe.ApiData.hasData(cachedNfAsUserEntries.value)) {
      for (const iterRes of cachedNfAsUserEntries.value.data) {
        for (const nfEntry of iterRes.entries) {
          if (nfEntry.entry.entry_id === entryId) {
            f(nfEntry.entry);
            break;
          }
        }
      }
    }
    const cachedInboxEntries = this.draftState.apiCache.inboxEntries;
    if (cfe.ApiData.hasData(cachedInboxEntries.value)) {
      for (const iterRes of cachedInboxEntries.value.data) {
        for (const nfEntry of iterRes.entries) {
          if (nfEntry.entry.entry_id === entryId) {
            f(nfEntry.entry);
            break;
          }
        }
      }
    }
  }

  public mutateFeedEntryByUrl(feedId: string, primaryUrl: string, f: (entry: api.feed.IFeedEntryReference) => void) {
    if (this.draftState.exploredItem) {
      const testPrimaryUrl =
        this.draftState.exploredItem.entry.strong_ref?.url ?? this.draftState.exploredItem.entry.url;
      if (primaryUrl === testPrimaryUrl) {
        // Passes by duck-typing, but fragile.
        f(this.draftState.exploredItem.entry);
      }
    }
    if (feedId !== 'newsfeed') {
      for (const argKey of this.draftState.apiCache.feedEntryKeysByFeedId.get(feedId) ?? []) {
        const cachedEntries = this.draftState.apiCache.feedEntries.get(argKey);
        if (cachedEntries !== undefined && cfe.ApiData.hasData(cachedEntries.value)) {
          for (const iterRes of cachedEntries.value.data) {
            for (const entry of iterRes.entries) {
              const testPrimaryUrl = entry.strong_ref?.url ?? entry.url;
              if (primaryUrl === testPrimaryUrl) {
                f(entry);
                break;
              }
            }
          }
        }
      }
    }

    const cachedNfEntries = this.draftState.apiCache.newsfeedEntries;
    if (cfe.ApiData.hasData(cachedNfEntries.value)) {
      for (const iterRes of cachedNfEntries.value.data) {
        for (const nfEntry of iterRes.entries) {
          const testPrimaryUrl = nfEntry.entry.strong_ref?.url ?? nfEntry.entry.url;
          if (primaryUrl === testPrimaryUrl) {
            f(nfEntry.entry);
            break;
          }
        }
      }
    }
    const cachedNfAsUserEntries = this.draftState.apiCache.newsfeedAsUserEntries[1];
    if (cfe.ApiData.hasData(cachedNfAsUserEntries.value)) {
      for (const iterRes of cachedNfAsUserEntries.value.data) {
        for (const nfEntry of iterRes.entries) {
          const testPrimaryUrl = nfEntry.entry.strong_ref?.url ?? nfEntry.entry.url;
          if (primaryUrl === testPrimaryUrl) {
            f(nfEntry.entry);
            break;
          }
        }
      }
    }
    const cachedInboxEntries = this.draftState.apiCache.inboxEntries;
    if (cfe.ApiData.hasData(cachedInboxEntries.value)) {
      for (const iterRes of cachedInboxEntries.value.data) {
        for (const nfEntry of iterRes.entries) {
          const testPrimaryUrl = nfEntry.entry.strong_ref?.url ?? nfEntry.entry.url;
          if (primaryUrl === testPrimaryUrl) {
            f(nfEntry.entry);
            break;
          }
        }
      }
    }
  }

  //
  // Persist KB Cursor
  //

  public setFeedKbCursor(extendedFeedId: store.ExtendedFeedId, cursor: store.NewsfeedKbCursor | null) {
    this.draftState.feedKbCursor.set(extendedFeedId, cursor);
  }

  //
  // Staff Options
  //

  public setAgentMode(val: boolean) {
    this.draftState.agentMode = val;
  }

  //
  // Media Options
  //

  public setAutoPlayMedia(val: boolean) {
    this.draftState.autoPlayMedia = val;
  }

  //
  // Toast
  //

  public addToast(toast: Omit<IToast, 'id'>) {
    const newToast: IToast = {
      action: toast.action,
      body: toast.body,
      header: toast.header,
      icon: toast.icon,
      id: randomId(),
      onTtlDismiss: toast.onTtlDismiss,
    };
    this.draftState.toast = newToast;
  }

  public removeToast(toastId?: string) {
    if (!toastId || this.draftState.toast?.id === toastId) {
      this.draftState.toast = null;
    }
  }
}

function randomId() {
  return Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .substr(2, 10);
}

export const MainActionCreators = createActionCreators(MainReducer);
