import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { MainActionCreators } from '../state/reducer';

import useApiClient from './hooks/useApiClient';
import useApiDo from './hooks/useApiDo';
import useUserMeInternal from './hooks/useUserMeInternal';
import Button from './lib/Button';
import Spinner from './lib/Spinner';

const FollowButton = (props: { feed: api.feed.IFeedInfo; sm?: boolean }) => {
  const dispatch = useDispatch();
  const apiClient = useApiClient();
  const accountInfo = useUserMeInternal();
  const [inFlight, setInFlight] = useState(false);

  const { apiDo: apiFeedFollow, okToast } = useApiDo(apiClient, apiClient.feedFollow);
  const { apiDo: apiFeedUnfollow } = useApiDo(apiClient, apiClient.feedUnfollow);

  const onFollow = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setInFlight(true);
    if (accountInfo) {
      apiFeedFollow(
        { feed_id: props.feed.feed_id },
        {
          onFinally: () => setInFlight(false),
          onResult: res => {
            dispatch(MainActionCreators.updateFeed(res.feed));
            okToast('Following');
          },
        },
      );
    } else {
      window.location.href = `/login?next=${cfe.ApiHelpers.getUrlPathnameForFeed(props.feed)}`;
    }
  };

  const onUnfollow = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setInFlight(true);
    if (accountInfo) {
      apiFeedUnfollow(
        { feed_id: props.feed.feed_id },
        {
          onFinally: () => setInFlight(false),
          onResult: res => {
            dispatch(MainActionCreators.updateFeed(res.feed));
            okToast('Unfollowed');
          },
        },
      );
    } else {
      setInFlight(false);
      window.location.href = `/login?next=${cfe.ApiHelpers.getUrlPathnameForFeed(props.feed)}`;
    }
  };

  // Outer-div is necessary to insulate the button which is inline from
  // stretching itself vertically based on container height.
  return (
    <div>
      {props.feed.for_viewer.following ? (
        <Button sm={props.sm} outline onClick={onUnfollow} disabled={inFlight}>
          <span>Following</span>
          <Spinner.Presence>
            {inFlight ? (
              <span className="tw-pl-2">
                <Spinner show={true} />
              </span>
            ) : null}
          </Spinner.Presence>
        </Button>
      ) : (
        <Button sm={props.sm} onClick={onFollow} disabled={inFlight}>
          <span>Follow</span>
          <Spinner.Presence>
            {inFlight ? (
              <span className="tw-pl-2">
                <Spinner show={true} color="light" />
              </span>
            ) : null}
          </Spinner.Presence>
        </Button>
      )}
    </div>
  );
};

export default FollowButton;
