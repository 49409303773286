import clsx from 'clsx';
import React from 'react';
import Button from './lib/Button';

const ActionButton = (props: {
  title: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode;
  className?: string;
  sm?: boolean;
  primary?: boolean;
  block?: boolean;
  minimal?: boolean;
  muted?: boolean;
  boundWidth?: boolean;
  onAuxClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}) => (
  <Button
    type="button"
    variant={props.primary ? 'primary' : 'secondary'}
    sm={props.sm}
    block={props.block}
    className={clsx(
      props.className,
      '!tw-border !tw-border-layout-line-light dark:!tw-border-layout-line-dark',
      '!tw-min-h-[1.75rem] !tw-min-w-[2.5rem]',
      props.boundWidth ? '!tw-max-w-[4.5rem]' : null,
      props.minimal ? '!tw-px-3' : null,
      props.minimal ? '!tw-bg-transparent !tw-shadow-none hover:!tw-bg-purple-300 dark:hover:!tw-bg-purple-700' : null,
    )}
    textColorOverride={
      props.muted
        ? 'tw-text-muted hover:tw-text-body-light hover:dark:tw-text-body-dark'
        : props.minimal
          ? 'tw-text-body-light dark:tw-text-body-dark'
          : undefined
    }
    title={props.title}
    onClick={captureEvent(props.onClick)}
    onAuxClick={props.onAuxClick ? captureEvent(props.onAuxClick) : undefined}
  >
    {props.children}
  </Button>
);

const captureEvent = (
  f: (event: React.MouseEvent<HTMLButtonElement>) => void,
): ((event: React.MouseEvent<HTMLButtonElement>) => void) => {
  return (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    f(event);
  };
};

export const ButtonLabel = (props: { text: string; xsHide: boolean }) => (
  <span className={clsx('tw-ml-1 sm:tw-inline-flex', props.xsHide ? 'tw-hidden' : null, 'tw-text-xs tw-leading-none')}>
    {props.text}
  </span>
);

export default ActionButton;
