import clsx from 'clsx';
import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import React, { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { scrollTo } from 'scroll-js';

import { MainActionCreators, ThreadCommentCacheLoc } from '../state/reducer';
import * as store from '../state/store';

import ActionButton, { ButtonLabel } from './ActionButton';
import EgoMarkdown from './EgoMarkdown';
import useAgentMode from './hooks/useAgentMode';
import useApiDo from './hooks/useApiDo';
import useToast from './hooks/useToast';
import { AccountInfoMaybe } from './hooks/useUserMeInternal';
import CircleDownIcon from './icon/CircleDown';
import CircleQuestionIcon from './icon/CircleQuestion';
import EllipsisIcon from './icon/EllipsisIcon';
import SpeakerIcon from './icon/SpeakerIcon';
import SubscriberIcon from './icon/SubscriberIcon';
import ThumbsDownIcon from './icon/ThumbsDownIcon';
import ThumbsUpIcon from './icon/ThumbsUpIcon';
import { useKeyPress } from './KeyPressContext';
import Badge from './lib/Badge';
import Button from './lib/Button';
import Spinner from './lib/Spinner';
import TextInput from './lib/TextInput';
import ToolTip from './lib/ToolTip';
import { useModalManager } from './ModalManagerContext';
import SectionHeaderTab from './SectionHeaderTab';
import SmartFeedLink from './SmartFeedLink';
import ThreadCommentMenuModal from './ThreadCommentMenuModal';
import ThreadCommentModal, { RootCommentOpts } from './ThreadCommentModal';
import TryAgainButton from './TryAgainButton';

export interface ThreadProps {
  accountInfo: AccountInfoMaybe;
  apiClient: api.SuperegoClient;
  navToFeed: (feed: api.feed.IFeedInfo) => void;
  headerOpts?: {
    title: string;
    icon?: React.ReactNode;
    addCommentTitle?: string;
    hide?: boolean;
  };
  showQuickCommentInput?: boolean;
  rootCommentOpts?: RootCommentOpts;
  size?: SizeOpts;
  threadId: string;
  viaEntryId?: string;
  authorUserId?: string;
  watermark?: number;
  seenWatermark?: number;
  className?: string;
  enableAiPrompt?: boolean;
  kb?: {
    kbSelected: 'going-up' | 'going-down' | null;
    kbNextSection: () => void;
    kbPrevSection: () => void;
    scrollTarget?: HTMLDivElement;
    priority?: number;
  };
}

// NOTE: If multiple <Thread> components are created for the same threadId,
// their size options will clash if different because they share the same
// underlying cache. It will still work, but whatever is already fetched will
// be maintained and only future queries will use the size option of the
// current <Thread> component.
interface SizeOpts {
  initRepliesPerLevel: number;
  initDepth: number;
  repliesPerLevel: number;
  depth: number;
}

export const Thread = React.memo(
  (props: ThreadProps) => {
    const dispatch = useDispatch();
    const { pushModal } = useModalManager();
    const agentMode = useAgentMode();

    const [threadCollapsed, setThreadCollapsed] = React.useState(false);

    const showCommentModalTopLevel = React.useCallback(
      () => showCommentModal({ kind: 'root', threadId: props.threadId }),
      [props.threadId],
    );

    const { result: threadRoot, refresh: threadRootRefresh } = cfe.ApiHook.useApiReadCache(
      props.apiClient,
      props.apiClient.threadGetTree,
      {
        max_depth: props.size?.initDepth ?? 1,
        max_replies_per_level: props.size?.repliesPerLevel ?? 1,
        thread_id: props.threadId,
      },
      res => res,
      value => dispatch(MainActionCreators.apiCacheSetThreadRoot(props.threadId, value)),
      () =>
        useSelector<store.IAppState, cfe.ApiHook.CacheUnit<cfe.ApiData.Data<api.thread.IGetTreeResult>>>(
          state => state.apiCache.threadRoot.get(props.threadId) ?? cfe.ApiHook.getCacheEmptySingleton(),
        ),
      undefined,
      undefined,
      120,
    );

    const siblingCommentsSoFar = React.useMemo(() => {
      if (cfe.ApiData.hasData(threadRoot)) {
        return new Set(threadRoot.data.root_comments.map(comment => comment.comment_id));
      } else {
        return new Set<string>();
      }
    }, [threadRoot]);

    const showCommentModal = React.useCallback(
      (cacheLoc: ThreadCommentCacheLoc, parentComment?: api.thread.IComment, onLoadAskAi?: boolean) => {
        pushModal({
          component: modalProps => (
            <ThreadCommentModal
              {...modalProps}
              addCommentProps={{
                enableAiPrompt: props.enableAiPrompt ? { kind: 'yes', onLoad: onLoadAskAi ?? false } : { kind: 'no' },
                parentComment,
                rootCommentOpts: props.rootCommentOpts,
                successCb: (newComment, thread) => {
                  if (parentComment) {
                    batch(() => {
                      dispatch(MainActionCreators.apiCacheThreadInfoUpdate(props.threadId, thread));
                      dispatch(
                        MainActionCreators.apiCacheThreadCommentMutate(cacheLoc, parentComment.comment_id, comment => {
                          comment.replies_count += 1;
                          comment.replies.unshift(newComment);
                        }),
                      );
                    });
                  } else {
                    batch(() => {
                      dispatch(MainActionCreators.apiCacheThreadInfoUpdate(props.threadId, thread));
                      dispatch(MainActionCreators.apiCacheThreadCommentAddToRoot(props.threadId, newComment));
                    });
                  }
                },
                threadId: props.threadId,
                // Default title is important for when thread has no header.
                title: props.headerOpts?.addCommentTitle ?? 'New Thread',
                viaEntryId: props.viaEntryId,
              }}
            />
          ),
          dupKey: 'new-comment',
          kind: 'generic',
        });
      },
      [
        props.threadId,
        props.headerOpts?.addCommentTitle,
        props.headerOpts?.title,
        props.viaEntryId,
        props.enableAiPrompt,
      ],
    );

    const showCommentMenuModal = React.useCallback(
      (cacheLoc: ThreadCommentCacheLoc, commentId: string) => {
        pushModal({
          component: modalProps => (
            <ThreadCommentMenuModal
              {...modalProps}
              commentId={commentId}
              deleteSuccessCb={deletedComment =>
                dispatch(MainActionCreators.apiCacheThreadCommentUpdate(cacheLoc, deletedComment))
              }
            />
          ),
          dupKey: 'comment-menu',
          kind: 'generic',
        });
      },
      [props.threadId],
    );

    const quickCommentAdd = React.useCallback(
      (newComment: api.thread.IComment, thread: api.thread.IThreadInfo) => {
        batch(() => {
          dispatch(MainActionCreators.apiCacheThreadInfoUpdate(props.threadId, thread));
          dispatch(MainActionCreators.apiCacheThreadCommentAddToRoot(props.threadId, newComment));
        });
      },
      [props.threadId],
    );

    const [seenWatermark, _] = useState(props.seenWatermark);

    const firstLoad = React.useRef(true);
    useEffect(() => {
      // If the watermark has changed, update the thread tree without clearing
      // the existing tree to get latest comments.
      if (firstLoad.current) {
        firstLoad.current = false;
      } else {
        threadRootRefresh();
      }
    }, [props.watermark]);

    const topKbIndex = props.headerOpts?.hide ? 0 : -1;
    const [kbIndex, setKbIndex] = useState(topKbIndex);

    useEffect(() => {
      if (props.kb?.kbSelected) {
        if (props.kb.kbSelected === 'going-down') {
          setKbIndex(topKbIndex);
        } else if (!threadCollapsed && cfe.ApiData.hasData(threadRoot) && threadRoot.data.root_comments.length > 0) {
          setKbIndex(threadRoot.data.root_comments.length - 1);
        } else {
          setKbIndex(topKbIndex);
        }
      }
    }, [props.kb?.kbSelected, threadCollapsed]);

    // If last comment is kb-selected, delegate most kb handling to it.
    const kbLastSelected =
      kbIndex !== -1 && cfe.ApiData.hasData(threadRoot) && threadRoot.data.root_comments.length - 1 === kbIndex;

    const threadCollapseToggle = React.useCallback(() => {
      if (!threadCollapsed && kbIndex > -1 && props.kb?.kbNextSection) {
        props.kb.kbNextSection();
        setKbIndex(topKbIndex);
      }
      setThreadCollapsed(!threadCollapsed);
    }, [kbIndex, threadCollapsed]);

    useKeyPress(
      'n',
      () => {
        // n: Select next comment or jump to next section.
        if (cfe.ApiData.hasData(threadRoot) && !threadCollapsed) {
          if (kbIndex >= threadRoot.data.root_comments.length - 1) {
            props.kb?.kbNextSection();
          } else {
            setKbIndex(kbIndex + 1);
          }
        } else {
          props.kb?.kbNextSection();
        }
      },
      !props.kb?.kbSelected || kbLastSelected,
      props.kb?.priority,
    );

    const prevSiblings = React.useCallback(() => {
      if (kbIndex === topKbIndex) {
        props.kb?.kbPrevSection();
      } else {
        setKbIndex(kbIndex - 1);
      }
    }, [kbIndex, topKbIndex, props.kb?.kbPrevSection]);

    useKeyPress(
      'p',
      () => {
        // p: Select prev comment, header, or jump to prev section.
        prevSiblings();
      },
      !props.kb?.kbSelected || kbLastSelected,
      props.kb?.priority,
    );

    useKeyPress(
      ['Enter', 'o'],
      () => {
        // enter/o: Toggle thread collapse
        threadCollapseToggle();
      },
      !props.kb?.kbSelected || kbIndex !== -1,
      props.kb?.priority,
    );

    useKeyPress(
      'r',
      () => {
        // r: Open new comment modal
        showCommentModalTopLevel();
      },
      !props.kb?.kbSelected || kbIndex !== -1,
      props.kb?.priority,
    );

    return (
      <>
        {props.headerOpts && !props.headerOpts.hide ? (
          <SectionHeaderTab
            className={props.showQuickCommentInput ? '!tw-mb-4' : undefined}
            title={props.headerOpts.title}
            titleClassName={threadCollapsed ? 'tw-italic' : undefined}
            titleSuffix={
              props.headerOpts.icon && cfe.ApiData.hasData(threadRoot) ? (
                <div className="tw-flex tw-items-center">
                  {props.headerOpts.icon}
                  <span className="tw-ml-1">{threadRoot.data.thread.comment_count}</span>
                </div>
              ) : undefined
            }
            kb={
              props.kb
                ? { selected: props.kb.kbSelected !== null && kbIndex === -1, scrollTarget: props.kb?.scrollTarget }
                : undefined
            }
            right={
              props.headerOpts.addCommentTitle ? (
                <div className="tw-flex tw-gap-x-2 tw-items-center">
                  {props.enableAiPrompt ? (
                    <ToolTip
                      placement="left"
                      overlay={
                        <div className="tw-w-40">
                          <p className="tw-mb-0">
                            When replying to a question, you can ask an AI with knowledge of the story for help. You can
                            even use it to answer your own questions.
                          </p>
                        </div>
                      }
                    >
                      <span className="tw-text-primary tw-select-none tw-whitespace-nowrap tw-text-xs sm:tw-text-sm">
                        AI <span className="tw-hidden sm:tw-inline">Assist</span> <CircleQuestionIcon size="1rem" />
                      </span>
                    </ToolTip>
                  ) : null}
                  <Button sm className="tw-mr-4" onClick={showCommentModalTopLevel}>
                    {props.headerOpts.addCommentTitle}
                  </Button>
                </div>
              ) : undefined
            }
            onClick={threadCollapseToggle}
          />
        ) : null}
        {!threadCollapsed ? (
          <div className={clsx('tw-flex tw-flex-col tw-gap-y-2 tw-cursor-auto', props.className)}>
            {cfe.ApiData.hasData(threadRoot) && props.showQuickCommentInput ? (
              <QuickComment
                apiClient={props.apiClient}
                threadId={props.threadId}
                commentCount={threadRoot.data.thread.comment_count}
                successCb={quickCommentAdd}
              />
            ) : null}
            {!props.showQuickCommentInput &&
            cfe.ApiData.hasData(threadRoot) &&
            showSuggestedQuestion(threadRoot.data) ? (
              <div>
                {props.enableAiPrompt ? (
                  <SuggestedQuestionButton
                    accountInfo={props.accountInfo}
                    apiClient={props.apiClient}
                    threadId={props.threadId}
                    viaEntryId={props.viaEntryId}
                    successCb={quickCommentAdd}
                    showCommentModal={showCommentModal}
                  />
                ) : (
                  <span>&mdash;</span>
                )}
              </div>
            ) : null}
            {cfe.ApiData.hasData(threadRoot) ? (
              threadRoot.data.root_comments.map((comment, index) => (
                <Comment
                  key={comment.comment_id}
                  accountInfo={props.accountInfo}
                  agentMode={agentMode}
                  apiClient={props.apiClient}
                  navToFeed={props.navToFeed}
                  threadId={props.threadId}
                  authorUserId={props.authorUserId}
                  showCommentModal={showCommentModal}
                  showCommentMenuModal={showCommentMenuModal}
                  seenWatermark={seenWatermark}
                  comment={comment}
                  commentCacheLoc={{ kind: 'root', threadId: props.threadId }}
                  earlierSiblings={siblingCommentsSoFar}
                  last={threadRoot.data.root_comments.length - 1 === index}
                  additionalSiblingCount={threadRoot.data.root_comment_count - index - 1}
                  kb={{
                    nextSection: props.kb?.kbNextSection,
                    prevSiblings,
                    priority: props.kb?.priority,
                    scrollTarget: props.kb?.scrollTarget,
                    selected: !!props.kb?.kbSelected && index === kbIndex,
                  }}
                />
              ))
            ) : cfe.ApiData.isLoading(threadRoot) ? (
              <div>
                <Spinner sm show />
              </div>
            ) : cfe.ApiData.isError(threadRoot) ? (
              <div>
                <TryAgainButton onClick={threadRootRefresh} />
              </div>
            ) : null}
          </div>
        ) : null}
      </>
    );
  },
  (prevProps, nextProps) =>
    prevProps.accountInfo === nextProps.accountInfo &&
    prevProps.apiClient === nextProps.apiClient &&
    prevProps.navToFeed === nextProps.navToFeed &&
    prevProps.headerOpts?.addCommentTitle === nextProps.headerOpts?.addCommentTitle &&
    prevProps.headerOpts?.title === nextProps.headerOpts?.title &&
    prevProps.headerOpts?.hide === nextProps.headerOpts?.hide &&
    prevProps.showQuickCommentInput === nextProps.showQuickCommentInput &&
    prevProps.rootCommentOpts?.maxLength === nextProps.rootCommentOpts?.maxLength &&
    prevProps.rootCommentOpts?.simpleTextOnly === nextProps.rootCommentOpts?.simpleTextOnly &&
    prevProps.rootCommentOpts?.singleLine === nextProps.rootCommentOpts?.singleLine &&
    prevProps.size?.depth === nextProps.size?.depth &&
    prevProps.size?.initDepth === nextProps.size?.initDepth &&
    prevProps.size?.initRepliesPerLevel === nextProps.size?.initRepliesPerLevel &&
    prevProps.size?.repliesPerLevel === nextProps.size?.repliesPerLevel &&
    prevProps.threadId === nextProps.threadId &&
    prevProps.viaEntryId === nextProps.viaEntryId &&
    prevProps.authorUserId === nextProps.authorUserId &&
    prevProps.watermark === nextProps.watermark &&
    prevProps.seenWatermark === nextProps.seenWatermark &&
    prevProps.className === nextProps.className &&
    prevProps.enableAiPrompt === nextProps.enableAiPrompt &&
    prevProps.kb?.kbSelected === nextProps.kb?.kbSelected &&
    prevProps.kb?.kbNextSection === nextProps.kb?.kbNextSection &&
    prevProps.kb?.kbPrevSection === nextProps.kb?.kbPrevSection &&
    prevProps.kb?.scrollTarget === nextProps.kb?.scrollTarget &&
    prevProps.kb?.priority === nextProps.kb?.priority,
);

const showSuggestedQuestion = (threadRoot: api.thread.IGetTreeResult): boolean => {
  if (threadRoot.root_comment_count > threadRoot.root_comments.length) {
    // Avoid the suggested question since it may have been asked already but
    // isn't loaded in the initial batch.
    return false;
  }
  for (const comment of threadRoot.root_comments) {
    if (comment.body === 'What are the key takeaways?') {
      return false;
    }
  }
  return true;
};

const SuggestedQuestionButton = (props: {
  accountInfo: AccountInfoMaybe;
  apiClient: api.SuperegoClient;
  threadId: string;
  viaEntryId?: string;
  successCb: (comment: api.thread.IComment, thread: api.thread.IThreadInfo) => void;
  showCommentModal: (
    cacheLoc: ThreadCommentCacheLoc,
    parentComment?: api.thread.IComment,
    onLoadAskAi?: boolean,
  ) => void;
}) => {
  const dispatch = useDispatch();
  const { apiDo: apiThreadCommentAdd, errToast } = useApiDo(props.apiClient, props.apiClient.threadCommentAdd);
  const [inFlight, setInFlight] = React.useState(false);
  return (
    <div>
      <Button
        sm
        variant="secondary"
        onClick={() => {
          if (props.accountInfo === null) {
            window.location.href = `/login?next=${window.location.pathname}`;
            return;
          }
          setInFlight(true);
          apiThreadCommentAdd(
            {
              body: 'What are the key takeaways?',
              thread_id: props.threadId,
              via: props.viaEntryId,
            },
            {
              onResult: res => {
                props.successCb(res.comment, res.thread);
                // WARN: Use props.threadId instead of the API response threadId
                // in case the local version is a placeholder threadId.
                props.showCommentModal({ kind: 'root', threadId: props.threadId }, res.comment, true);
                if (props.viaEntryId) {
                  props.apiClient.feedEntryGet({ entry_id: props.viaEntryId }).then(resp => {
                    if (resp.kind === api.StatusCode.Ok) {
                      dispatch(MainActionCreators.updateFeedEntry(resp.result.feed.feed_id, resp.result.entry));
                    }
                  });
                }
              },
              onRouteErr: (err, defaultErrToast) => {
                if (err['.tag'] === 'slow_down') {
                  errToast('Slow down', `Try again in ${cfe.Formatter.secondsToHoursMinsSecsStr(err.wait_period)}`);
                } else {
                  defaultErrToast();
                }
              },
            },
          );
        }}
      >
        Try asking "What are the key takeaways?"
      </Button>
      <Spinner sm show={inFlight} className="tw-ml-2" />
    </div>
  );
};

interface CommentKbIndexSelf {
  kind: 'self';
}

interface CommentKbIndexMoreBtn {
  kind: 'more-btn';
}

interface CommentKbIndexSiblings {
  kind: 'siblings';
  siblingIndex: number;
}

type CommentKbIndex = CommentKbIndexSelf | CommentKbIndexMoreBtn | CommentKbIndexSiblings;

const Comment = React.memo(
  (props: {
    accountInfo: AccountInfoMaybe;
    agentMode: boolean;
    apiClient: api.SuperegoClient;
    navToFeed: (feed: api.feed.IFeedInfo) => void;
    size?: Omit<SizeOpts, 'initDepth' | 'initRepliesPerLevel'>;
    threadId: string;
    authorUserId?: string;
    showCommentModal: (cacheLoc: ThreadCommentCacheLoc, parentComment?: api.thread.IComment) => void;
    showCommentMenuModal: (cacheLoc: ThreadCommentCacheLoc, commentId: string) => void;
    seenWatermark?: number;
    comment: api.thread.IComment;
    commentCacheLoc: ThreadCommentCacheLoc;
    earlierSiblings: Set<string>;
    last: boolean;
    additionalSiblingCount: number;
    kb?: {
      selected: boolean;
      scrollTarget?: HTMLDivElement;
      nextSection?: () => void;
      prevSiblings?: () => void;
      priority?: number;
    };
  }) => {
    const dispatch = useDispatch();
    const [fetchSiblings, setFetchSiblings] = React.useState(false);
    const { result: threadSiblings } = cfe.ApiHook.useApiReadCache(
      props.apiClient,
      props.apiClient.threadGetSiblings,
      {
        comment_id: props.comment.comment_id,
        max_depth: props.size?.depth ?? 1,
        max_replies_per_level: props.size?.depth ?? 2,
        thread_id: props.threadId,
      },
      res => res,
      value => dispatch(MainActionCreators.apiCacheSetThreadSiblings(props.threadId, props.comment.comment_id, value)),
      () =>
        useSelector<store.IAppState, cfe.ApiHook.CacheUnit<cfe.ApiData.Data<api.thread.IGetSiblingsResult>>>(
          state =>
            state.apiCache.threadSiblings.get(props.threadId)?.get(props.comment.comment_id) ??
            cfe.ApiHook.getCacheEmptySingleton(),
        ),
      props.additionalSiblingCount === 0 || !fetchSiblings,
      {
        onResult: res => dispatch(MainActionCreators.apiCacheThreadInfoUpdate(props.threadId, res.thread)),
      },
      120,
    );
    const [fetchReplies, setFetchReplies] = React.useState(false);
    const { result: threadReplies } = cfe.ApiHook.useApiReadCache(
      props.apiClient,
      props.apiClient.threadGetReplies,
      {
        comment_id: props.comment.comment_id,
        max_depth: props.size?.depth ?? 2,
        max_replies_per_level: props.size?.repliesPerLevel ?? 1,
        thread_id: props.threadId,
      },
      res => res,
      value => dispatch(MainActionCreators.apiCacheSetThreadReplies(props.threadId, props.comment.comment_id, value)),
      () =>
        useSelector<store.IAppState, cfe.ApiHook.CacheUnit<cfe.ApiData.Data<api.thread.IGetRepliesResult>>>(
          state =>
            state.apiCache.threadReplies.get(props.threadId)?.get(props.comment.comment_id) ??
            cfe.ApiHook.getCacheEmptySingleton(),
        ),
      props.comment.replies.length > 0 || !fetchReplies,
      {
        onResult: res => {
          batch(() => {
            dispatch(MainActionCreators.apiCacheThreadInfoUpdate(props.threadId, res.thread));
            const updatedComment = { ...res.top };
            updatedComment.replies = [];
            dispatch(MainActionCreators.apiCacheThreadCommentUpdate(props.commentCacheLoc, updatedComment));
          });
        },
      },
      120,
    );
    const collapseReplies = React.useCallback(() => {
      dispatch(
        MainActionCreators.apiCacheThreadCommentMutate(
          props.commentCacheLoc,
          props.comment.comment_id,
          comment => ((comment as CommentUI).collapsed = !(comment as CommentUI).collapsed),
        ),
      );
    }, [props.comment.comment_id]);

    const [kbIndex, setKbIndex] = React.useState<CommentKbIndex>({ kind: 'self' });
    const divRef = React.useRef<HTMLDivElement | null>(null);
    React.useEffect(() => {
      if (!props.kb?.selected || !divRef.current || kbIndex.kind !== 'self') {
        return;
      }
      if (props.kb?.scrollTarget) {
        const scrollYTarget =
          divRef.current.getBoundingClientRect().top + props.kb.scrollTarget.scrollTop - window.innerHeight / 2 + 100;
        scrollTo(props.kb.scrollTarget, { top: scrollYTarget, easing: 'ease-in-out', duration: 70 });
      } else {
        const scrollYTarget =
          divRef.current.getBoundingClientRect().top + window.pageYOffset - window.innerHeight / 2 + 100;
        scrollTo(window, { top: scrollYTarget, easing: 'ease-in-out', duration: 70 });
      }
    }, [props.kb?.selected, props.kb?.scrollTarget, divRef.current, kbIndex.kind]);

    const kbLastSiblingSelected =
      kbIndex.kind === 'siblings' &&
      cfe.ApiData.hasData(threadSiblings) &&
      threadSiblings.data.siblings.length - 1 === kbIndex.siblingIndex;

    useKeyPress(
      ['Enter', 'o'],
      () => {
        // enter/o: Toggle comment collapse
        if (kbIndex.kind === 'self') {
          collapseReplies();
        } else if (kbIndex.kind === 'more-btn') {
          setFetchSiblings(true);
          setKbIndex({ kind: 'siblings', siblingIndex: 0 });
        }
      },
      !props.kb?.selected || (kbIndex.kind !== 'self' && kbIndex.kind !== 'more-btn'),
      props.kb?.priority,
    );
    useKeyPress(
      'r',
      () => {
        // r: Open reply modal
        props.showCommentModal(props.commentCacheLoc, props.comment);
      },
      !props.kb?.selected || kbIndex.kind !== 'self',
      props.kb?.priority,
    );
    useKeyPress(
      'n',
      () => {
        if (kbIndex.kind === 'self') {
          if (cfe.ApiData.hasData(threadSiblings)) {
            setKbIndex({ kind: 'siblings', siblingIndex: 0 });
          } else if (fetchSiblings || props.additionalSiblingCount === 0) {
            if (props.kb?.nextSection) {
              props.kb.nextSection();
            }
          } else {
            setKbIndex({ kind: 'more-btn' });
          }
        } else if (kbIndex.kind === 'more-btn') {
          if (props.kb?.nextSection) {
            props.kb.nextSection();
          }
        } else if (kbIndex.kind === 'siblings' && cfe.ApiData.hasData(threadSiblings)) {
          if (kbIndex.siblingIndex < threadSiblings.data.siblings.length - 1) {
            setKbIndex({ kind: 'siblings', siblingIndex: kbIndex.siblingIndex + 1 });
          } else {
            if (props.kb?.nextSection) {
              props.kb.nextSection();
            }
          }
        }
      },
      !props.kb?.selected || !props.last || kbLastSiblingSelected,
      props.kb?.priority,
    );

    useKeyPress(
      'p',
      () => {
        if (kbIndex.kind === 'more-btn') {
          setKbIndex({ kind: 'self' });
        } else if (kbIndex.kind === 'self' && props.kb?.prevSiblings) {
          props.kb.prevSiblings();
        } else if (kbIndex.kind === 'siblings' && cfe.ApiData.hasData(threadSiblings)) {
          if (kbIndex.siblingIndex > 0) {
            setKbIndex({ kind: 'siblings', siblingIndex: kbIndex.siblingIndex - 1 });
          } else {
            setKbIndex({ kind: 'self' });
          }
        }
      },
      !props.kb?.selected || !props.last || kbLastSiblingSelected,
      props.kb?.priority,
    );
    const prevSiblings = React.useCallback(() => {
      setKbIndex({ kind: 'self' });
    }, []);
    const collapsed = (props.comment as CommentUI).collapsed === true;
    return (
      <>
        <div ref={divRef} className="tw-relative">
          {props.comment.parent_id ? (
            <div className="tw-absolute -tw-left-[1.6rem] -tw-top-1 tw-text-layout-line-light dark:tw-text-layout-line-dark">
              <svg width="20" height="12" viewBox="0 0 50 30" xmlns="http://www.w3.org/2000/svg">
                <path d="M 0 0 C 0 20, 40 30, 50 30" stroke="currentColor" fill="transparent" stroke-width={4} />
              </svg>
            </div>
          ) : null}
          <CommentBox
            accountInfo={props.accountInfo}
            comment={props.comment}
            collapseReplies={collapseReplies}
            seenWatermark={props.seenWatermark}
            kbActive={!!props.kb?.selected && kbIndex.kind === 'self'}
          >
            <CommentMatter
              accountInfo={props.accountInfo}
              agentMode={props.agentMode}
              apiClient={props.apiClient}
              navToFeed={props.navToFeed}
              threadId={props.threadId}
              authorUserId={props.authorUserId}
              showCommentModal={props.showCommentModal}
              showCommentMenuModal={props.showCommentMenuModal}
              comment={props.comment}
              commentCacheLoc={props.commentCacheLoc}
              seenWatermark={props.seenWatermark}
            />
          </CommentBox>
          {!collapsed && props.comment.replies_count > 0 ? (
            <CommentStripe collapseReplies={collapseReplies}>
              {cfe.ApiData.hasData(threadReplies)
                ? threadReplies.data.top.replies.map((comment, index) => (
                    <Comment
                      key={comment.comment_id}
                      accountInfo={props.accountInfo}
                      agentMode={props.agentMode}
                      apiClient={props.apiClient}
                      navToFeed={props.navToFeed}
                      threadId={props.threadId}
                      authorUserId={props.authorUserId}
                      showCommentModal={props.showCommentModal}
                      showCommentMenuModal={props.showCommentMenuModal}
                      comment={comment}
                      commentCacheLoc={{
                        kind: 'replies',
                        repliesCommentId: props.comment.comment_id,
                        threadId: props.threadId,
                      }}
                      seenWatermark={props.seenWatermark}
                      earlierSiblings={new Set(threadReplies.data.top.replies.map(reply => reply.comment_id))}
                      last={threadReplies.data.top.replies.length - 1 === index}
                      additionalSiblingCount={
                        threadReplies.data.top.replies_count - threadReplies.data.top.replies.length
                      }
                    />
                  ))
                : props.comment.replies.map((comment, index) => (
                    <Comment
                      key={comment.comment_id}
                      accountInfo={props.accountInfo}
                      agentMode={props.agentMode}
                      apiClient={props.apiClient}
                      navToFeed={props.navToFeed}
                      threadId={props.threadId}
                      authorUserId={props.authorUserId}
                      showCommentModal={props.showCommentModal}
                      showCommentMenuModal={props.showCommentMenuModal}
                      comment={comment}
                      commentCacheLoc={props.commentCacheLoc}
                      seenWatermark={props.seenWatermark}
                      earlierSiblings={new Set(props.comment.replies.map(reply => reply.comment_id))}
                      last={props.comment.replies.length - 1 === index}
                      additionalSiblingCount={props.comment.replies_count - props.comment.replies.length}
                    />
                  ))}
              {props.comment.replies.length === 0 && !cfe.ApiData.hasData(threadReplies) ? (
                <LoadMoreRepliesButton
                  replyCount={props.comment.replies_count}
                  onClick={() => setFetchReplies(true)}
                  loading={cfe.ApiData.isLoading(threadReplies)}
                />
              ) : null}
            </CommentStripe>
          ) : null}
        </div>
        {props.last && props.additionalSiblingCount > 0 ? (
          <>
            {!cfe.ApiData.hasData(threadSiblings) ? (
              <LoadMoreRepliesButton
                replyCount={props.additionalSiblingCount}
                onClick={() => setFetchSiblings(true)}
                loading={cfe.ApiData.isLoading(threadSiblings)}
                kbActive={props.kb?.selected && props.last && kbIndex.kind === 'more-btn'}
              />
            ) : null}
            {cfe.ApiData.hasData(threadSiblings)
              ? threadSiblings.data.siblings
                  .filter(comment => !props.earlierSiblings.has(comment.comment_id))
                  .map((comment, index) => (
                    <Comment
                      key={comment.comment_id}
                      accountInfo={props.accountInfo}
                      agentMode={props.agentMode}
                      apiClient={props.apiClient}
                      navToFeed={props.navToFeed}
                      threadId={props.threadId}
                      authorUserId={props.authorUserId}
                      showCommentModal={props.showCommentModal}
                      showCommentMenuModal={props.showCommentMenuModal}
                      comment={comment}
                      commentCacheLoc={{
                        kind: 'siblings',
                        siblingsCommentId: props.comment.comment_id,
                        threadId: props.threadId,
                      }}
                      seenWatermark={props.seenWatermark}
                      earlierSiblings={
                        new Set([
                          ...props.earlierSiblings,
                          ...threadSiblings.data.siblings.map(sibling => sibling.comment_id),
                        ])
                      }
                      last={threadSiblings.data.siblings.length - 1 === index}
                      additionalSiblingCount={props.additionalSiblingCount - index - 1}
                      kb={{
                        nextSection: props.kb?.nextSection,
                        prevSiblings,
                        priority: props.kb?.priority,
                        scrollTarget: props.kb?.scrollTarget,
                        selected:
                          !!props.kb?.selected &&
                          props.last &&
                          kbIndex.kind === 'siblings' &&
                          kbIndex.siblingIndex === index,
                      }}
                    />
                  ))
              : null}
          </>
        ) : null}
      </>
    );
  },
  (prevProps, nextProps) =>
    prevProps.accountInfo === nextProps.accountInfo &&
    prevProps.agentMode === nextProps.agentMode &&
    prevProps.apiClient === nextProps.apiClient &&
    prevProps.navToFeed === nextProps.navToFeed &&
    prevProps.size?.depth === nextProps.size?.depth &&
    prevProps.size?.repliesPerLevel === nextProps.size?.repliesPerLevel &&
    prevProps.threadId === nextProps.threadId &&
    prevProps.authorUserId === nextProps.authorUserId &&
    prevProps.showCommentModal === nextProps.showCommentModal &&
    prevProps.showCommentMenuModal === nextProps.showCommentMenuModal &&
    prevProps.seenWatermark === nextProps.seenWatermark &&
    prevProps.comment === nextProps.comment &&
    // Omitted: Safe to assume commentCacheLoc cannot change.
    // Omitted: Safe to assume earlierSiblings cannot change.
    prevProps.last === nextProps.last &&
    prevProps.additionalSiblingCount === nextProps.additionalSiblingCount &&
    prevProps.kb?.selected === nextProps.kb?.selected &&
    prevProps.kb?.scrollTarget === nextProps.kb?.scrollTarget &&
    prevProps.kb?.nextSection === nextProps.kb?.nextSection &&
    prevProps.kb?.prevSiblings === nextProps.kb?.prevSiblings &&
    prevProps.kb?.priority === nextProps.kb?.priority,
);

interface CommentUI extends api.thread.IComment {
  collapsed?: boolean;
}

const CommentMatter = React.memo(
  (props: {
    accountInfo: AccountInfoMaybe;
    agentMode: boolean;
    apiClient: api.SuperegoClient;
    navToFeed: (feed: api.feed.IFeedInfo) => void;
    threadId: string;
    authorUserId?: string;
    showCommentModal: (cacheLoc: ThreadCommentCacheLoc, parentComment?: api.thread.IComment) => void;
    showCommentMenuModal: (cacheLoc: ThreadCommentCacheLoc, commentId: string) => void;
    comment: api.thread.IComment;
    commentCacheLoc: ThreadCommentCacheLoc;
    seenWatermark?: number;
  }) => {
    const dispatch = useDispatch();
    const { setToast } = useToast();
    const isOwnComment = props.accountInfo?.user_id === props.comment.author.user_id;
    const collapsed = (props.comment as CommentUI).collapsed === true;
    const emphasisClass = collapsed
      ? 'tw-text-muted hover:tw-text-muted'
      : 'tw-font-bold tw-text-primary hover:tw-text-primary';
    const commentWatermark = new Date(props.comment.ts).getTime() / 1000;
    const commentUnseen = !isOwnComment && props.seenWatermark !== undefined && commentWatermark > props.seenWatermark;
    const { apiDo: apiThreadCommentUpvote } = useApiDo(props.apiClient, props.apiClient.threadCommentUpvote);
    const { apiDo: apiThreadCommentDownvote } = useApiDo(props.apiClient, props.apiClient.threadCommentDownvote);
    const { apiDo: apiThreadCommentUnvote } = useApiDo(props.apiClient, props.apiClient.threadCommentUnvote);
    const collapseReplies = React.useCallback(() => {
      dispatch(
        MainActionCreators.apiCacheThreadCommentMutate(
          props.commentCacheLoc,
          props.comment.comment_id,
          comment => ((comment as CommentUI).collapsed = !(comment as CommentUI).collapsed),
        ),
      );
      // A comment's location cannot change so there are no deps.
    }, []);
    return (
      <div className="tw-w-full">
        <div className={clsx('tw-flex tw-items-center tw-text-xs', collapsed ? 'tw-italic' : null)}>
          {collapsed ? (
            <span role="button" className="tw-mr-1" onClick={collapseReplies}>
              [+{props.comment.replies_count}]
            </span>
          ) : null}
          {commentUnseen ? (
            <Badge xs variant="primary" className="tw-mr-1">
              New
            </Badge>
          ) : null}
          {props.comment.deleted_at ? <span className={clsx('tw-mr-1', emphasisClass)}>[Removed]</span> : null}
          <SmartFeedLink
            goToFeed={props.navToFeed}
            apiClient={props.apiClient}
            feedRef={`u/${props.comment.author.user_id}`}
            displayHref={`/${props.comment.author.username}`}
            className={emphasisClass}
          >
            {props.comment.author.name}
          </SmartFeedLink>
          {props.comment.author.is_subscriber ? <SubscriberIcon size="0.9" offsetUp className="tw-ml-1" /> : null}
          {props.comment.author.user_id === props.authorUserId ? (
            <SpeakerIcon
              size="0.9rem"
              className={clsx('tw-ml-1', collapsed ? 'tw-text-muted' : 'tw-text-perpul-light dark:tw-text-perpul-dark')}
            />
          ) : null}
          <span className="tw-ml-1 tw-text-muted">
            <DateLine ts={props.comment.ts} />
            {props.comment.last_edited ? (
              <span className="font-italic">
                &bull; edited <DateLine ts={props.comment.last_edited} />
              </span>
            ) : null}
          </span>
          <div
            role="button"
            title="Collapse comment"
            style={{ minHeight: '1rem' }}
            className="tw-grow tw-select-none"
            onClick={collapseReplies}
          ></div>
        </div>
        {!collapsed && !props.comment.deleted_at ? (
          <div className="tw-mt-2">
            <EgoMarkdown
              variant="comment"
              content={props.comment.body}
              goToFeed={props.navToFeed}
              contentVideoSpecs={props.comment.video_specs}
              contentLinkSpecs={props.comment.link_specs}
            />
          </div>
        ) : null}
        {!collapsed ? (
          <div
            className="tw-flex tw-items-center tw-ml-1 tw-mt-2 tw-text-muted hover:tw-text-primary tw-select-none"
            style={props.comment.for_viewer.voted ? { visibility: 'visible' } : undefined}
          >
            <span
              role="button"
              onClick={() => {
                if (!props.accountInfo) {
                  setToast({ header: 'Sign in to up-vote', icon: 'frown' });
                  return;
                }
                if (isOwnComment) {
                  return;
                }
                if (props.comment.for_viewer.voted) {
                  if (props.comment.for_viewer.voted.up) {
                    dispatch(
                      MainActionCreators.apiCacheThreadCommentMutate(
                        props.commentCacheLoc,
                        props.comment.comment_id,
                        comment => {
                          comment.for_viewer.voted = undefined;
                          comment.points -= 1;
                        },
                      ),
                    );
                    apiThreadCommentUnvote({ comment_id: props.comment.comment_id });
                  } else {
                    dispatch(
                      MainActionCreators.apiCacheThreadCommentMutate(
                        props.commentCacheLoc,
                        props.comment.comment_id,
                        comment => {
                          comment.for_viewer.voted = { up: true };
                          comment.points += 2;
                        },
                      ),
                    );
                    apiThreadCommentUpvote({ comment_id: props.comment.comment_id });
                  }
                } else {
                  dispatch(
                    MainActionCreators.apiCacheThreadCommentMutate(
                      props.commentCacheLoc,
                      props.comment.comment_id,
                      comment => {
                        comment.for_viewer.voted = { up: true };
                        comment.points += 1;
                      },
                    ),
                  );
                  apiThreadCommentUpvote({ comment_id: props.comment.comment_id });
                }
              }}
              className={clsx(
                'tw-text-[1.05rem] tw-font-bold',
                props.comment.for_viewer.voted?.up
                  ? 'tw-text-purple-600 dark:tw-text-purple-500'
                  : isOwnComment
                    ? 'tw-text-muted'
                    : null,
              )}
            >
              <ThumbsUpIcon size={18} />
            </span>
            <span className="tw-mx-1 tw-font-bold tw-text-center tw-text-xs tw-min-w-[1.25em]">
              {props.comment.points}
            </span>
            <span
              role="button"
              onClick={() => {
                if (!props.accountInfo) {
                  setToast({ header: 'Sign in to down-vote', icon: 'frown' });
                  return;
                }
                if (isOwnComment) {
                  return;
                }
                if (props.comment.for_viewer.voted) {
                  if (props.comment.for_viewer.voted.up) {
                    dispatch(
                      MainActionCreators.apiCacheThreadCommentMutate(
                        props.commentCacheLoc,
                        props.comment.comment_id,
                        comment => {
                          comment.for_viewer.voted = { up: false };
                          comment.points -= 2;
                        },
                      ),
                    );
                    apiThreadCommentDownvote({ comment_id: props.comment.comment_id });
                  } else {
                    dispatch(
                      MainActionCreators.apiCacheThreadCommentMutate(
                        props.commentCacheLoc,
                        props.comment.comment_id,
                        comment => {
                          comment.for_viewer.voted = undefined;
                          comment.points += 1;
                        },
                      ),
                    );
                    apiThreadCommentUnvote({ comment_id: props.comment.comment_id });
                  }
                } else {
                  dispatch(
                    MainActionCreators.apiCacheThreadCommentMutate(
                      props.commentCacheLoc,
                      props.comment.comment_id,
                      comment => {
                        comment.for_viewer.voted = { up: false };
                        comment.points -= 1;
                      },
                    ),
                  );
                  apiThreadCommentDownvote({ comment_id: props.comment.comment_id });
                }
              }}
              className={clsx(
                'tw-text-[1.05rem] tw-font-bold',
                props.comment.for_viewer.voted?.up === false
                  ? 'tw-text-purple-600 dark:tw-text-purple-500'
                  : isOwnComment
                    ? 'tw-text-muted'
                    : null,
              )}
            >
              <ThumbsDownIcon size={18} />
            </span>
            <span
              role="button"
              onClick={() => {
                if (!props.accountInfo) {
                  setToast({ header: 'Sign in to reply', icon: 'frown' });
                  return;
                }
                props.showCommentModal(props.commentCacheLoc, props.comment);
              }}
              className="tw-ml-4 tw-font-bold tw-text-sm"
            >
              Reply
            </span>
            {(isOwnComment || props.agentMode) && !props.comment.deleted_at ? (
              <span
                role="button"
                onClick={() => props.showCommentMenuModal(props.commentCacheLoc, props.comment.comment_id)}
                className="tw-ml-4"
              >
                <EllipsisIcon size="1rem" />
              </span>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  },
  (prevProps, nextProps) =>
    prevProps.accountInfo === nextProps.accountInfo &&
    prevProps.agentMode === nextProps.agentMode &&
    prevProps.apiClient === nextProps.apiClient &&
    prevProps.navToFeed === nextProps.navToFeed &&
    prevProps.threadId === nextProps.threadId &&
    prevProps.authorUserId === nextProps.authorUserId &&
    prevProps.showCommentModal === nextProps.showCommentModal &&
    prevProps.showCommentMenuModal === nextProps.showCommentMenuModal &&
    prevProps.comment === nextProps.comment &&
    // NOTE: Safe to assume commentCacheLoc cannot change.
    prevProps.seenWatermark === nextProps.seenWatermark,
);

export const DateLine = (props: { ts: string }) => {
  const [tsShort, tsLong] = cfe.Formatter.getTsFormats(props.ts);
  return <span title={tsLong}>{tsShort}</span>;
};

const CommentStripe = React.memo(
  (props: { collapseReplies: () => void; children: React.ReactNode }) => (
    <div className="tw-w-full tw-flex tw-mt-2">
      <div
        role="button"
        onClick={props.collapseReplies}
        className={clsx(
          'tw-ml-1 tw-pr-6',
          'tw-border-l-2 tw-border-solid tw-border-y-0 tw-border-r-0 tw-border-layout-line-light dark:tw-border-layout-line-dark',
          'hover:tw-border-purple-600 dark:hover:tw-border-purple-500',
          // Needs to render higher than comment-svg-stripe-offshoot-curve.
          'tw-z-10',
        )}
      />
      <div className="tw-flex tw-flex-col tw-gap-y-2 tw-grow">{props.children}</div>
    </div>
  ),
  (prevProps, nextProps) =>
    prevProps.collapseReplies === nextProps.collapseReplies && prevProps.children === nextProps.children,
);

const LoadMoreRepliesButton = (props: {
  replyCount: number;
  onClick: () => void;
  loading?: boolean;
  kbActive?: boolean;
}) => {
  const text = `${props.replyCount} more ${props.replyCount === 1 ? 'reply' : 'replies'}`;
  return (
    <div className="tw-flex tw-items-center">
      <ActionButton title={text} onClick={props.onClick} primary={props.kbActive}>
        <CircleDownIcon size="1rem" />
        <ButtonLabel text={text} xsHide={false} />
      </ActionButton>
      <div className="tw-ml-2"></div>
      <Spinner sm show={props.loading} />
    </div>
  );
};

const CommentBox = React.memo(
  (props: {
    accountInfo: AccountInfoMaybe;
    comment: api.thread.IComment;
    collapseReplies: () => void;
    seenWatermark?: number;
    kbActive: boolean;
    children: React.ReactNode;
  }) => {
    const isOwnComment = props.accountInfo?.user_id === props.comment.author.user_id;
    const commentWatermark = new Date(props.comment.ts).getTime() / 1000;
    const commentUnseen = !isOwnComment && props.seenWatermark !== undefined && commentWatermark > props.seenWatermark;
    return (
      // The negative margin counteracts the padding around the comment box.
      <div
        className={clsx(
          'tw-flex tw-py-2 -tw-ml-2 tw-pr-2',
          commentUnseen ? 'tw-bg-yellow-100 dark:tw-bg-[#211f02]' : null,
        )}
      >
        <div
          role="button"
          className={clsx(
            'tw-border-l-[4px] tw-border-r-[calc(0.5rem-4px)] tw-border-r-transparent',
            props.kbActive ? 'tw-border-l-perpul-light dark:tw-border-l-perpul-dark' : 'tw-border-l-transparent',
          )}
          onClick={props.collapseReplies}
        ></div>
        <div className="tw-w-full">{props.children}</div>
      </div>
    );
  },
  (prevProps, nextProps) =>
    prevProps.accountInfo === nextProps.accountInfo &&
    prevProps.comment === nextProps.comment &&
    prevProps.collapseReplies === nextProps.collapseReplies &&
    prevProps.seenWatermark === nextProps.seenWatermark &&
    prevProps.kbActive === nextProps.kbActive &&
    prevProps.children === nextProps.children,
);

const QuickComment = React.memo(
  (props: {
    apiClient: api.SuperegoClient;
    threadId: string;
    commentCount: number;
    successCb: (comment: api.thread.IComment, thread: api.thread.IThreadInfo) => void;
  }) => {
    const [body, setBody] = React.useState('');

    const [inFlight, setInFlight] = React.useState(false);
    const { apiDo: apiThreadCommentAdd, errToast } = useApiDo(props.apiClient, props.apiClient.threadCommentAdd);

    const onCommentSubmit = () => {
      if (body.length === 0) {
        return;
      }
      setInFlight(true);
      apiThreadCommentAdd(
        { thread_id: props.threadId, body },
        {
          onFinally: () => setInFlight(false),
          onResult: res => {
            setBody('');
            props.successCb(res.comment, res.thread);
          },
          onRouteErr: (err, defaultErrToast) => {
            if (err['.tag'] === 'slow_down') {
              errToast('Slow down', `Try again in ${cfe.Formatter.secondsToHoursMinsSecsStr(err.wait_period)}`);
            } else {
              defaultErrToast();
            }
          },
        },
      );
    };

    return (
      <form className="tw-grow tw-relative tw-flex lg:tw-max-w-lg">
        <TextInput
          sm
          className="!tw-bg-transparent !tw-rounded-lg tw-pr-[4rem] placeholder:tw-italic"
          containerClassName="tw-grow"
          id="entry-reply"
          type="text"
          placeholder={props.commentCount === 0 ? 'Start the conversation...' : 'Start a new thread...'}
          onChange={e => setBody(e.currentTarget.value)}
          value={body}
          onClick={(e: React.MouseEvent) => e.stopPropagation()}
        />
        <Button
          sm
          className="tw-absolute tw-top-[2px] tw-right-[2px] tw-z-[4] !tw-rounded-lg !tw-min-h-[calc(1.75rem-4px)]"
          type="submit"
          disabled={body.length === 0 || inFlight}
          variant={body.length === 0 ? 'secondary' : 'primary'}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            onCommentSubmit();
          }}
          onSubmit={e => {
            e.preventDefault();
            e.stopPropagation();
            onCommentSubmit();
          }}
        >
          Send
        </Button>
      </form>
    );
  },
  (prevProps, nextProps) =>
    prevProps.apiClient === nextProps.apiClient &&
    prevProps.threadId === nextProps.threadId &&
    prevProps.commentCount === nextProps.commentCount &&
    prevProps.successCb === nextProps.successCb,
);

export default Thread;
