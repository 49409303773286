import clsx from 'clsx';
import { HTMLMotionProps, m } from 'framer-motion';
import React from 'react';
import { Link } from 'react-router-dom';

interface ButtonProps extends HTMLMotionProps<'button'> {
  variant?: 'primary' | 'secondary' | 'danger' | 'success';
  block?: boolean;
  outline?: boolean;
  sm?: boolean;
  textColorOverride?: string;
  wrapOk?: boolean;
  // Does not disable the button, but makes it appear as if it is
  mimicDisabled?: boolean;
}

/**
 * Children of Button can be customized to respond to the "hover" framer-motion
 * variant.
 */
const Button = (props: ButtonProps) => {
  const { variant, block, mimicDisabled, outline, sm, textColorOverride, wrapOk, ...rest } = props;
  return (
    <m.button
      type="button"
      {...rest}
      whileHover="hover"
      whileTap="active"
      variants={{
        active: {
          scale: props.block ? 1.05 : 1.1,
          transition: { duration: 0.2 },
        },
      }}
      className={clsx(
        'tw-inline-flex tw-items-center tw-justify-center tw-text-center',
        props.wrapOk ? null : 'tw-flex-nowrap tw-whitespace-nowrap',
        'tw-select-none', // makes text unselectable
        'tw-cursor-pointer disabled:!tw-cursor-default',
        props.sm ? 'tw-min-h-[1.75rem] tw-px-2' : 'tw-min-h-[2.5rem] tw-px-4',
        props.textColorOverride ??
          (!props.outline
            ? 'tw-text-white'
            : props.variant === 'danger'
              ? 'tw-text-red-700 dark:tw-text-red-700'
              : props.variant === 'success'
                ? 'tw-text-green-700 dark:tw-text-green-700'
                : props.variant === 'secondary'
                  ? 'tw-text-slate-700 dark:tw-text-slate-700'
                  : 'tw-text-perpul-light dark:tw-text-perpul-dark'),
        props.outline ? null : 'disabled:tw-text-zinc-100 dark:disabled:tw-text-zinc-400',
        props.sm ? 'tw-text-sm' : 'tw-text-[0.95rem]',
        'tw-font-medium tw-leading-none',
        props.outline ? 'tw-border tw-border-2 tw-border-solid' : null,
        !props.outline
          ? 'tw-border-0'
          : props.variant === 'danger'
            ? 'tw-border-red-700 dark:tw-border-red-700'
            : props.variant === 'success'
              ? 'tw-border-green-700 dark:tw-border-green-700'
              : props.variant === 'secondary'
                ? 'tw-border-slate-700 dark:tw-border-slate-700'
                : 'tw-border-perpul-light dark:tw-border-perpul-dark',
        'tw-rounded tw-shadow',
        'tw-duration-300',
        props.outline
          ? 'tw-bg-zinc-400/20 dark:tw-bg-zinc-700/20'
          : props.variant === 'danger'
            ? 'tw-bg-red-700 dark:tw-bg-red-700'
            : props.variant === 'success'
              ? 'tw-bg-green-700 dark:tw-bg-green-700'
              : props.variant === 'secondary'
                ? 'tw-bg-slate-700 dark:tw-bg-slate-700'
                : 'tw-bg-perpul-light dark:tw-bg-perpul-dark',
        '!tw-bg-opacity-[0.85] disabled:!tw-bg-opacity-60 disabled:dark:!tw-bg-opacity-60',
        'hover:!tw-bg-opacity-100 disabled:hover:!tw-bg-opacity-60 disabled:dark:hover:!tw-bg-opacity-60',
        'focus:tw-outline-0',
        'focus-visible:tw-outline focus-visible:tw-outline-offset-2 focus-visible:tw-outline-2 focus-visible:tw-outline-black focus-visible:dark:tw-outline-white',
        props.block ? 'tw-w-full' : null,
        props.mimicDisabled ? '!tw-bg-opacity-60' : null,
        props.className,
      )}
    >
      {props.children}
    </m.button>
  );
};

export const ButtonLink = (props: ButtonProps & { to: string }) => (
  <Link to={props.to} className="hover:tw-no-underline">
    <Button {...props} />
  </Link>
);

export const ButtonLinkExternal = (props: ButtonProps & { href: string }) => (
  <a href={props.href} className="hover:tw-no-underline">
    <Button {...props} />
  </a>
);

export default Button;
