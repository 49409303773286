import clsx from 'clsx';
import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import { m } from 'framer-motion';
import React from 'react';

import ActionButton, { ButtonLabel } from './ActionButton';
import { FeedEntryOps } from './hooks/useFeedEntryOps';
import EditIcon from './icon/EditIcon';
import EgoSlice from './icon/EgoSlice';
import ExploreIcon from './icon/ExploreIcon';
import PruneIcon from './icon/PruneIcon';
import RefreshIcon from './icon/RefreshIcon';
import SaveIcon from './icon/SaveIcon';
import SendToIcon from './icon/SendToIcon';
import TrashIcon from './icon/TrashIcon';
import CmdInput from './lib/CmdInput';

const ScaleOnHover = (props: { children: React.ReactNode }) => (
  <m.div variants={{ hover: { scale: 1.075 } }}>{props.children}</m.div>
);

const RotateOnHover = (props: { children: React.ReactNode }) => (
  <m.div variants={{ hover: { rotateZ: -45 } }}>{props.children}</m.div>
);

const FeedEntryActions = (props: {
  feed: api.feed.IFeedInfo;
  entry: api.feed.IFeedEntryReference;
  actions: FeedEntryOps;
  hideArchive?: boolean;
  vertical?: boolean;
  agentMode: boolean;
}) => {
  return (
    <div className={clsx('tw-flex tw-grow tw-items-center', props.vertical ? 'tw-flex-col tw-gap-y-2' : 'tw-gap-x-2')}>
      {/* HACK: The conditional addition of dividers is wonky and makes
      hidden assumptions about which action functions are set in correlated
      fashion.*/}
      {props.feed.type['.tag'] !== 'notif' && props.feed.type['.tag'] !== 'rec_for_you' ? (
        <>
          {props.actions.ego ? (
            <GiveEgoButton block minimal label={false} entry={props.entry} onClick={props.actions.ego} />
          ) : null}
          {props.actions.sendTo ? (
            <SendToButton
              block
              minimal
              label={false}
              entry={props.entry}
              agentMode={props.agentMode}
              onClick={props.actions.sendTo}
            />
          ) : null}
          {props.actions.explore ? (
            <ExploreButton block minimal entry={props.entry} onClick={props.actions.explore} />
          ) : null}
          {props.agentMode && props.actions.remove ? (
            <RemoveButton block minimal label={false} onClick={props.actions.remove} />
          ) : null}
          {props.actions.saveForLater ? (
            <SaveToLibraryButton
              block
              minimal
              label={false}
              entry={props.entry}
              agentMode={props.agentMode}
              onClick={props.actions.saveForLater}
            />
          ) : props.actions.saveToLibrary ? (
            <SaveToLibraryButton
              block
              minimal
              label={false}
              entry={props.entry}
              agentMode={props.agentMode}
              onClick={props.actions.saveToLibrary}
            />
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export const FeedEntryCompactActions = (props: { entry: api.feed.IFeedEntryReference; actions: FeedEntryOps }) => {
  const btnclassName = clsx(
    'tw-text-muted hover:tw-text-body-light dark:hover:tw-text-body-dark',
    'tw-hidden sm:tw-flex tw-flex-1 tw-items-center tw-justify-center',
    'hover:tw-bg-purple-300 dark:hover:tw-bg-purple-700',
  );
  return (
    <div className="tw-flex tw-h-full tw-mr-5">
      <m.div
        role="button"
        title="Give Ego (1)"
        whileHover="hover"
        onClick={e => {
          if (e.ctrlKey || e.metaKey) {
            e.stopPropagation();
            window.open(cfe.ApiHelpers.getUrlPathnameForEntry(props.entry.entry_id), '_blank');
            return;
          }
          e.stopPropagation();
          if (props.actions.ego) {
            props.actions.ego();
          }
        }}
        className={clsx(
          btnclassName,
          'tw-border-l tw-border-solid tw-border-y-0 tw-border-r-0 tw-border-layout-line-light dark:tw-border-layout-line-dark',
        )}
      >
        <RotateOnHover>
          <EgoSlice size="1.1rem" />
        </RotateOnHover>
      </m.div>
      <m.div
        role="button"
        title="Send To (2)"
        whileHover="hover"
        onClick={e => {
          if (e.ctrlKey || e.metaKey) {
            e.stopPropagation();
            window.open(cfe.ApiHelpers.getUrlPathnameForEntry(props.entry.entry_id), '_blank');
            return;
          }
          e.stopPropagation();
          if (props.actions.sendTo) {
            props.actions.sendTo();
          }
        }}
        className={btnclassName}
      >
        <ScaleOnHover>
          <SendToIcon size="1.1rem" />
        </ScaleOnHover>
      </m.div>
      <m.div
        role="button"
        title="Metadata (I)"
        whileHover="hover"
        onClick={e => {
          if (e.ctrlKey || e.metaKey) {
            e.stopPropagation();
            window.open(cfe.ApiHelpers.getUrlPathnameForEntry(props.entry.entry_id), '_blank');
            return;
          }
          e.stopPropagation();
          props.actions.explore();
        }}
        className={btnclassName}
      >
        <ScaleOnHover>
          <ExploreIcon size="1.1rem" />
        </ScaleOnHover>
      </m.div>
      {props.actions.saveForLater ? (
        <m.div
          role="button"
          title="Save (3)"
          whileHover="hover"
          onClick={e => {
            if (props.actions.saveForLater) {
              e.stopPropagation();
              props.actions.saveForLater();
            }
          }}
          className={btnclassName}
        >
          <ScaleOnHover>
            <SaveIcon size="1.1rem" />
          </ScaleOnHover>
        </m.div>
      ) : null}
    </div>
  );
};

export const FeedEntryExploreActions = (props: {
  entry: api.feed.IFeedEntryReference;
  actions: FeedEntryOps;
  agentMode: boolean;
}) => {
  return (
    <div>
      <div className="tw-flex tw-gap-x-2">
        {!props.actions.opensToExplore ? <OpenButton label entry={props.entry} onClick={props.actions.open} /> : null}
        {props.actions.ego ? <GiveEgoButton label entry={props.entry} onClick={props.actions.ego} /> : null}
        {props.actions.sendTo ? (
          <SendToButton label entry={props.entry} agentMode={props.agentMode} onClick={props.actions.sendTo} />
        ) : null}
        {props.actions.saveForLater ? (
          <SaveToLibraryButton
            label
            entry={props.entry}
            agentMode={props.agentMode}
            onClick={props.actions.saveForLater}
          />
        ) : props.actions.saveToLibrary ? (
          <SaveToLibraryButton
            label
            entry={props.entry}
            agentMode={props.agentMode}
            onClick={props.actions.saveToLibrary}
          />
        ) : null}
        {props.actions.remove ? <RemoveButton label onClick={props.actions.remove} /> : null}
        {props.actions.prune ? <PruneButton label onClick={props.actions.prune} /> : null}
      </div>
      {props.actions.editPost || (props.actions.extractMetadata && props.agentMode) ? (
        <div className="tw-flex tw-mt-2 tw-gap-x-2">
          {props.actions.editPost ? <EditPostButton onClick={props.actions.editPost} /> : null}
          {props.actions.extractMetadata ? <ExtractMetadataButton onClick={props.actions.extractMetadata} /> : null}
          {props.agentMode ? (
            <div className="tw-flex tw-items-center tw-text-sm">
              <CmdInput cmd="Shift + R" />
              <ButtonLabel text="Refresh" xsHide={false} />
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

const EditPostButton = (props: { minimal?: boolean; onClick: () => void }) => (
  <ActionButton
    minimal={props.minimal}
    boundWidth={props.minimal}
    muted={props.minimal}
    title="Edit post"
    onClick={props.onClick}
  >
    <ScaleOnHover>
      <EditIcon size="0.85rem" />
    </ScaleOnHover>
    <ButtonLabel text="Edit Post" xsHide />
  </ActionButton>
);

const ExploreButton = (props: {
  block?: boolean;
  minimal?: boolean;
  label?: boolean;
  entry: api.feed.IFeedEntryReference;
  onClick: () => void;
}) => (
  <ActionButton
    block={props.block}
    minimal={props.minimal}
    boundWidth={props.minimal}
    muted={props.minimal}
    title="Metadata (I)"
    onClick={e => {
      if (e.ctrlKey || e.metaKey) {
        e.stopPropagation();
        window.open(cfe.ApiHelpers.getUrlPathnameForEntry(props.entry.entry_id), '_blank');
        return;
      }
      props.onClick();
    }}
    onAuxClick={() => window.open(cfe.ApiHelpers.getUrlPathnameForEntry(props.entry.entry_id), '_blank')}
  >
    <ScaleOnHover>
      <ExploreIcon size="0.95rem" />
    </ScaleOnHover>
    {props.label ? <ButtonLabel text="Metadata" xsHide /> : null}
  </ActionButton>
);

const ExtractMetadataButton = (props: { minimal?: boolean; onClick: () => void }) => (
  <ActionButton
    minimal={props.minimal}
    boundWidth={props.minimal}
    muted={props.minimal}
    title="Extract metadata"
    onClick={props.onClick}
  >
    <ScaleOnHover>
      <RefreshIcon size="1rem" offsetUp />
    </ScaleOnHover>
    <ButtonLabel text="Metadata" xsHide />
  </ActionButton>
);

const OpenButton = (props: {
  block?: boolean;
  minimal?: boolean;
  label: boolean;
  entry: api.feed.IFeedEntryReference;
  onClick: () => void;
}) => (
  <ActionButton
    block={props.block}
    minimal={props.minimal}
    boundWidth={props.minimal}
    muted={props.minimal}
    title="Open (o)"
    onClick={props.onClick}
    primary
  >
    <ButtonLabel text="Open" xsHide={false} />
  </ActionButton>
);

const GiveEgoButton = (props: {
  block?: boolean;
  minimal?: boolean;
  label: boolean;
  entry: api.feed.IFeedEntryReference;
  onClick: () => void;
}) => (
  <ActionButton
    block={props.block}
    minimal={props.minimal}
    boundWidth={props.minimal}
    muted={props.minimal}
    title="Give Ego (1)"
    onClick={props.onClick}
  >
    <RotateOnHover>
      <EgoSlice size={props.minimal ? 15 : 16} />
    </RotateOnHover>
    {props.entry.feed_ref_counts && props.entry.feed_ref_counts.ego > 0 ? (
      <span className="tw-ml-1 tw-text-xs">{cfe.Formatter.abbreviateNumber(props.entry.feed_ref_counts.ego)}</span>
    ) : props.label ? (
      <ButtonLabel text="Ego" xsHide />
    ) : null}
  </ActionButton>
);

const SendToButton = (props: {
  block?: boolean;
  minimal?: boolean;
  label: boolean;
  entry: api.feed.IFeedEntryReference;
  agentMode: boolean;
  onClick: () => void;
}) => (
  <ActionButton
    block={props.block}
    minimal={props.minimal}
    boundWidth={props.minimal}
    muted={props.minimal}
    title="Send To (2)"
    onClick={props.onClick}
  >
    <ScaleOnHover>
      <SendToIcon size="0.95rem" offsetUp />
    </ScaleOnHover>
    {props.minimal && props.agentMode && props.entry.feed_ref_counts && props.entry.feed_ref_counts.shares > 0 ? (
      <span className="tw-ml-1 tw-text-xs">{cfe.Formatter.abbreviateNumber(props.entry.feed_ref_counts.shares)}</span>
    ) : props.label ? (
      <ButtonLabel text="Send" xsHide />
    ) : null}
  </ActionButton>
);

const RemoveButton = (props: { block?: boolean; minimal?: boolean; label: boolean; onClick: () => void }) => (
  <ActionButton
    block={props.block}
    minimal={props.minimal}
    boundWidth={props.minimal}
    muted={props.minimal}
    title="Remove from collection"
    onClick={props.onClick}
  >
    <ScaleOnHover>
      <TrashIcon size="0.9rem" />
    </ScaleOnHover>
    {props.label ? <ButtonLabel text="Remove" xsHide /> : null}
  </ActionButton>
);

const PruneButton = (props: { block?: boolean; minimal?: boolean; label: boolean; onClick: () => void }) => (
  <ActionButton
    block={props.block}
    minimal={props.minimal}
    boundWidth={props.minimal}
    muted={props.minimal}
    title="Prune from collection"
    onClick={props.onClick}
  >
    <ScaleOnHover>
      <PruneIcon size="0.9rem" />
    </ScaleOnHover>
    {props.label ? <ButtonLabel text="Prune" xsHide /> : null}
  </ActionButton>
);

const SaveToLibraryButton = (props: {
  block?: boolean;
  minimal?: boolean;
  label: boolean;
  entry: api.feed.IFeedEntryReference;
  agentMode: boolean;
  onClick: () => void;
}) => (
  <ActionButton
    block={props.block}
    minimal={props.minimal}
    boundWidth={props.minimal}
    muted={props.minimal}
    title="Save (3)"
    onClick={props.onClick}
  >
    <ScaleOnHover>
      <SaveIcon size="0.9rem" />
    </ScaleOnHover>
    {props.minimal && props.agentMode && props.entry.feed_ref_counts && props.entry.feed_ref_counts.saves > 0 ? (
      <span className="tw-ml-1 tw-text-xs">{cfe.Formatter.abbreviateNumber(props.entry.feed_ref_counts.saves)}</span>
    ) : props.label ? (
      <ButtonLabel text="Save" xsHide />
    ) : null}
  </ActionButton>
);

export default FeedEntryActions;
