import clsx from 'clsx';
import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import React from 'react';
import { scrollTo } from 'scroll-js';

import useNav from './hooks/useNav';

import FeedProfileImage from './FeedProfileImage';
import FollowButton from './FollowButton';
import useUserMeInternal from './hooks/useUserMeInternal';
import ArchiveIcon from './icon/ArchiveIcon';
import ClockIcon from './icon/ClockIcon';
import HeartIcon from './icon/HeartIcon';
import HistoryIcon from './icon/HistoryIcon';
import NotifIcon from './icon/NotifIcon';
import SubscriberIcon from './icon/SubscriberIcon';
import YouTubeIcon from './icon/YouTubeIcon';
import ListGroup from './lib/ListGroup';

export type ShowProfileImageBehavior = 'yes-or-placeholder' | 'yes-if-available' | 'no';

/**
 * By default, onClick opens to the feed, but this behavior can be changed with
 * onClickOverride.
 */
export interface FeedItemProps {
  feed: api.feed.IFeedInfo;
  hidePublisher?: boolean;
  hideFollowers?: boolean;
  hideFollowBtn?: boolean;
  hideBlurb?: boolean;
  showIcon?: boolean;
  sm?: boolean;
  titleRight?: React.ReactNode;
  rightEle?: React.ReactNode;
  onClickOverride?: () => void;
  onClickAfter?: () => void;
  disabled?: boolean;
  kbActive?: boolean;
  boldSubstr?: string;
  enableWindowScroll?: boolean;
  scrollTarget?: HTMLDivElement;
  disableKb?: boolean; // keyboard selection is not an option for this
  embed?: boolean;
  feedNameOverride?: string;
  mutedHover?: boolean;
  showProfileImage?: ShowProfileImageBehavior;
}

const FeedItem = (props: FeedItemProps) => {
  const { navToFeed } = useNav();
  const accountInfo = useUserMeInternal();
  const feedFqName: [string | null, string] = props.feedNameOverride
    ? [null, props.feedNameOverride]
    : cfe.ApiHelpers.getFeedFqNameParts(props.feed);

  const iconSize = props.sm ? '0.875rem' : '1rem';
  const icon =
    props.feed.type['.tag'] === 'archive' ? (
      <ArchiveIcon size={iconSize} offsetUp />
    ) : props.feed.type['.tag'] === 'favorites' ? (
      <HeartIcon size={iconSize} offsetUp />
    ) : props.feed.type['.tag'] === 'notif' ? (
      <NotifIcon size={iconSize} offsetUp />
    ) : props.feed.type['.tag'] === 'save_for_later' ? (
      <ClockIcon size={iconSize} offsetUp />
    ) : props.feed.type['.tag'] === 'visited' ? (
      <HistoryIcon size={iconSize} offsetUp />
    ) : null;

  const aRef = React.useRef<HTMLAnchorElement>(null);
  React.useEffect(() => {
    if (!props.enableWindowScroll || !props.kbActive || !aRef.current) {
      return;
    }
    if (props.scrollTarget) {
      const scrollYTarget =
        aRef.current.getBoundingClientRect().top + props.scrollTarget.scrollTop - window.innerHeight / 2 + 100;
      scrollTo(props.scrollTarget, { top: scrollYTarget, easing: 'ease-in-out', duration: 70 });
    } else {
      const scrollYTarget =
        aRef.current.getBoundingClientRect().top + window.pageYOffset - window.innerHeight / 2 + 100;
      scrollTo(window, { top: scrollYTarget, easing: 'ease-in-out', duration: 70 });
    }
  }, [props.kbActive, props.enableWindowScroll]);
  return (
    <a
      key={props.feed.feed_id}
      ref={aRef}
      className="tw-text-primary hover:tw-text-primary hover:tw-no-underline"
      href={props.disabled ? undefined : cfe.ApiHelpers.getUrlPathnameForFeed(props.feed)}
      onClick={
        props.disabled
          ? undefined
          : (e: React.MouseEvent<HTMLAnchorElement>) => {
              if (e.ctrlKey || e.metaKey) {
                return;
              }
              e.stopPropagation();
              e.preventDefault();
              if (props.onClickOverride) {
                props.onClickOverride();
              } else {
                navToFeed(props.feed);
                if (props.onClickAfter) {
                  props.onClickAfter();
                }
              }
            }
      }
    >
      <ListGroup.Item
        sm
        className={clsx(
          '!tw-py-3 !tw-px-4 sm:!tw-px-6',
          props.sm ? 'tw-text-sm' : 'tw-text-base',
          props.embed
            ? 'tw-border tw-border-solid tw-border-layout-line-dark dark:tw-border-layout-line-light tw-rounded-lg'
            : null,
        )}
        disabled={props.disabled}
        disableKb={props.disableKb}
        kbActive={props.kbActive}
        mutedHover={props.mutedHover}
        icon={
          (props.showProfileImage === 'yes-if-available' && props.feed.profile_image) ||
          props.showProfileImage === 'yes-or-placeholder' ? (
            <FeedProfileImage feed={props.feed} md placeholderOk={props.showProfileImage === 'yes-or-placeholder'} />
          ) : undefined
        }
      >
        <div className="tw-flex tw-justify-between tw-gap-x-4 tw-w-full">
          <div className="tw-flex tw-items-center tw-gap-x-2">
            <div className="tw-flex tw-flex-col">
              <div>
                <span className={clsx('tw-font-semibold', props.disabled ? 'tw-text-muted' : null)}>
                  {props.boldSubstr ? boldMatch(props.boldSubstr, feedFqName[1]) : feedFqName[1]}
                  {props.feed.type['.tag'] === 'ego' && props.feed.publisher!.is_subscriber ? (
                    <>
                      {' '}
                      <SubscriberIcon size={iconSize} offsetUp />
                    </>
                  ) : null}
                </span>
                {props.showIcon && icon ? <span className="tw-ml-2">{icon}</span> : null}
                {props.titleRight ? props.titleRight : null}
                <span className={clsx('tw-ml-1', props.sm ? 'tw-text-xs' : 'tw-text-sm')}>
                  {feedFqName[0] && !props.hidePublisher ? (
                    <>@{props.boldSubstr ? boldMatch(props.boldSubstr, feedFqName[0]) : feedFqName[0]}</>
                  ) : null}
                </span>
              </div>
              <div className={clsx(props.sm ? 'tw-text-xs' : 'tw-text-sm')}>
                {!props.hideFollowers ? <div>{props.feed.followers} followers</div> : null}
                {!props.hideBlurb && props.feed.blurb ? <div>{props.feed.blurb}</div> : null}
              </div>
            </div>
          </div>
          {!props.rightEle &&
          !props.onClickOverride &&
          !props.hideFollowBtn &&
          (!accountInfo || accountInfo.user_id !== props.feed.publisher?.user_id) ? (
            <FollowButton feed={props.feed} sm={props.sm} />
          ) : null}
          {props.rightEle}
        </div>
      </ListGroup.Item>
    </a>
  );
};

export const FeedItemContentIcon = (props: { feed: api.feed.IFeedInfo; sm?: boolean }) =>
  props.feed.primary_domain?.startsWith('www.youtube.com') ? (
    <YouTubeIcon size={props.sm ? '1.15rem' : '1.25rem'} className="tw-ml-2" />
  ) : null;

FeedItem.ContentIcon = FeedItemContentIcon;

function boldMatch(q: string, match: string): JSX.Element {
  const lcMatch = match.toLowerCase();
  const lcQ = q.toLowerCase();
  const index = lcMatch.indexOf(lcQ);
  if (index >= 0) {
    return (
      <>
        <span className="tw-whitespace-pre-wrap">{match.substring(0, index)}</span>
        <span className="tw-whitespace-pre-wrap tw-bold tw-text-purple-600 dark:tw-text-purple-500">
          {match.substring(index, index + q.length)}
        </span>
        <span className="tw-whitespace-pre-wrap">{match.substring(index + q.length)}</span>
      </>
    );
  } else {
    return <span>{match}</span>;
  }
}

export default FeedItem;
