import clsx from 'clsx';
import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MainActionCreators } from '../state/reducer';
import * as store from '../state/store';

import FeedEntryActions from './FeedEntryActions';
import {
  DiscussionPreviewMatter,
  QnaPreviewMatter,
  SourceTracePreviewMatter,
  StoryLowerMatter,
  StoryUnderMatter,
  StoryUpperMatter,
} from './FeedEntryMatter';
import useFeedEntryOps from './hooks/useFeedEntryOps';
import useUserMeInternal from './hooks/useUserMeInternal';
import Spinner from './lib/Spinner';
import TryAgainButton from './TryAgainButton';

const FeedEntryItemEmbed = React.memo(
  (props: { apiClient: api.SuperegoClient; navToFeed: (feed: api.feed.IFeedInfo) => void; entryId: string }) => {
    const dispatch = useDispatch();
    const { result: feedEntryGetResult, refresh: feedEntryGetRefresh } = cfe.ApiHook.useApiReadCache(
      props.apiClient,
      props.apiClient.feedEntryGet,
      { entry_id: props.entryId },
      res => res,
      value => dispatch(MainActionCreators.apiCacheSetFeedEntryStandalone(props.entryId, value)),
      () =>
        useSelector<
          store.IAppState,
          cfe.ApiHook.CacheUnit<cfe.ApiData.Data<api.feed.IEntryGetResult, api.feed.EntryGetError>>
        >(appState => appState.apiCache.feedEntryStandalone.get(props.entryId) ?? cfe.ApiHook.getCacheEmptySingleton()),
    );
    return (
      <div
        className={clsx(
          'tw-border tw-border-solid tw-border-layout-line-dark dark:tw-border-layout-line-light tw-rounded-lg',
          'hover:tw-bg-neutral-100 dark:hover:tw-bg-neutral-800',
        )}
      >
        {cfe.ApiData.hasData(feedEntryGetResult) ? (
          <FeedEntryItemResolvedEmbed
            apiClient={props.apiClient}
            navToFeed={props.navToFeed}
            feed={feedEntryGetResult.data.feed}
            entry={feedEntryGetResult.data.entry}
          />
        ) : cfe.ApiData.isError(feedEntryGetResult) ? (
          <div className="tw-flex tw-justify-center tw-py-4 tw-px-4 sm:tw-px-6 tw-text-sm tw-select-none">
            {feedEntryGetResult.error && feedEntryGetResult.error['.tag'] === 'no_permission' ? (
              <div>Sorry, you don't have permission to see this.</div>
            ) : feedEntryGetResult.error && feedEntryGetResult.error['.tag'] === 'bad_entry_id' ? (
              <div>Sorry, this doesn't exist.</div>
            ) : (
              <TryAgainButton onClick={feedEntryGetRefresh} />
            )}
          </div>
        ) : null}
        <Spinner.Presence>
          <div className="tw-flex tw-justify-center">
            {!cfe.ApiData.hasData(feedEntryGetResult) && cfe.ApiData.isLoading(feedEntryGetResult) ? (
              <Spinner className="tw-py-4" />
            ) : null}
          </div>
        </Spinner.Presence>
      </div>
    );
  },
  (prevProps, nextProps) =>
    prevProps.apiClient === nextProps.apiClient &&
    prevProps.navToFeed === nextProps.navToFeed &&
    prevProps.entryId === nextProps.entryId,
);

export const FeedEntryItemResolvedEmbed = (props: {
  apiClient: api.SuperegoClient;
  navToFeed: (feed: api.feed.IFeedInfo) => void;
  feed: api.feed.IFeedInfo;
  entry: api.feed.IFeedEntryReference;
}) => {
  const accountInfo = useUserMeInternal();
  const feedEntryOps = useFeedEntryOps(props.apiClient, accountInfo, props.feed, props.entry, false);
  const md = cfe.ApiHelpers.getEntryMetadata(props.entry);
  const portraitVideoImage =
    md['.tag'] === 'ready' &&
    !!md.image &&
    md.image.height > md.image.width &&
    md.content_type['.tag'] === 'video' &&
    !!md.content_type.portrait;
  return (
    <div role="button" className="tw-pt-4 tw-pb-2 tw-px-4 sm:tw-px-6 tw-text-base" onClick={() => feedEntryOps.open()}>
      <div className="tw-flex tw-flex-col tw-gap-y-2">
        <div className="tw-flex tw-flex-col">
          <StoryUpperMatter
            apiClient={props.apiClient}
            feed={props.feed}
            entry={props.entry}
            goToFeed={props.navToFeed}
            onOpenStory={feedEntryOps.open}
            compact={false}
            useTitleBadge
            clampTitle={false}
          />
          <StoryLowerMatter
            apiClient={props.apiClient}
            feed={props.feed}
            entry={props.entry}
            goToFeed={props.navToFeed}
            compact={false}
            showMiniBadgeLine={false}
            showFromLine
          />
          <StoryUnderMatter
            feed={props.feed}
            entry={props.entry}
            goToFeed={props.navToFeed}
            compact={false}
            hideBlurb
          />
        </div>
        {props.entry.discussion_preview || props.entry.trace_preview || props.entry.qna_preview ? (
          <div className={clsx('tw-flex tw-flex-col tw-gap-y-2')}>
            {props.entry.discussion_preview ? (
              <DiscussionPreviewMatter
                discussion_preview={props.entry.discussion_preview}
                onBadgeClick={feedEntryOps.explore}
                onOpen={feedEntryOps.openDiscussionPreview!}
              />
            ) : null}
            {props.entry.trace_preview ? (
              <SourceTracePreviewMatter
                trace_preview={props.entry.trace_preview}
                onBadgeClick={feedEntryOps.explore}
                onOpen={feedEntryOps.openTracePreview!}
              />
            ) : null}
            {props.entry.qna_preview ? (
              <QnaPreviewMatter qna_preview={props.entry.qna_preview} onBadgeClick={feedEntryOps.explore} />
            ) : null}
          </div>
        ) : null}
        {props.feed.display.mode['.tag'] !== 'headlines' && md['.tag'] === 'ready' && md.thumbnail ? (
          <div className="tw-grow tw-flex tw-items-center">
            <img
              width={md.thumbnail.width}
              height={md.thumbnail.height}
              className={clsx(
                // FIXME: Overrides top-margin by egomarkdown
                '!tw-mt-0 tw-w-full tw-rounded-lg',
                portraitVideoImage
                  ? 'tw-max-h-[400px] tw-object-contain'
                  : 'tw-max-h-[300px] tw-object-cover tw-object-center',
              )}
              src={md.thumbnail.url}
              loading="lazy"
            />
          </div>
        ) : null}
        <div className="tw-max-w-sm">
          <FeedEntryActions feed={props.feed} entry={props.entry} actions={feedEntryOps} agentMode={false} />
        </div>
      </div>
    </div>
  );
};

export default FeedEntryItemEmbed;
