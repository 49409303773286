import clsx from 'clsx';
import * as api from 'ego-sdk-js';
import React from 'react';
import { useDispatch } from 'react-redux';

import { MainActionCreators } from '../state/reducer';

import FeedProfileImage from './FeedProfileImage';
import useAgentMode from './hooks/useAgentMode';
import { useAuthedApiClient } from './hooks/useApiClient';
import useApiDo from './hooks/useApiDo';
import useMediaUploader from './hooks/useMediaUploader';
import Badge from './lib/Badge';
import Button from './lib/Button';
import Collapse from './lib/Collapse';
import FilePicker from './lib/FilePicker';
import Spinner from './lib/Spinner';

const ProfilePictureUpdate = (props: { feed: api.feed.IFeedInfo }) => {
  const dispatch = useDispatch();
  const agentMode = useAgentMode();
  const apiClient = useAuthedApiClient();
  const { apiDo: apiFeedSetImage, okToast } = useApiDo(apiClient, apiClient.feedSetImage);
  const [updateInFlight, setUpdateInFlight] = React.useState(false);

  const [imgFile, setImgFile] = React.useState<[File, string] | null>(null);
  const { uploadMedia, uploaderReset, uploaderStatus } = useMediaUploader('image', { '.tag': 'feed_profile_image' });
  const [replaceMode, setReplaceMode] = React.useState(false);
  return (
    <div>
      {props.feed.profile_image ? (
        <div className="tw-p-4 tw-flex tw-items-center tw-gap-x-4">
          <div>
            <FeedProfileImage feed={props.feed} />
          </div>
          {!replaceMode ? <Button onClick={() => setReplaceMode(true)}>Replace</Button> : null}
          <Button
            variant="danger"
            onClick={() => {
              setUpdateInFlight(true);
              apiFeedSetImage(
                { feed_id: props.feed.feed_id, media_upload_id: undefined },
                {
                  onFinally: () => setUpdateInFlight(false),
                  onResult: res => {
                    okToast('Updated!');
                    dispatch(MainActionCreators.updateFeed(res));
                  },
                },
              );
            }}
          >
            Remove
          </Button>
        </div>
      ) : null}
      {imgFile ? (
        <div className="tw-relative">
          <img
            src={uploaderStatus.kind === 'done' ? uploaderStatus.tempMediaUrl : null ?? imgFile[1]}
            className={clsx('tw-object-cover', uploaderStatus.kind !== 'done' ? 'tw-opacity-30' : null)}
            style={{ width: 120, height: 120 }}
          />
          {uploaderStatus.kind === 'uploading' ? (
            <div className="tw-absolute tw-top-1 tw-left-1">
              <Badge variant="info">{uploaderStatus.progress}%</Badge>
            </div>
          ) : uploaderStatus.kind === 'done' ? (
            <div className="tw-absolute tw-top-1 tw-left-1">
              <Badge variant="success">ready</Badge>
            </div>
          ) : null}
        </div>
      ) : (
        <Collapse open={!props.feed.profile_image || replaceMode}>
          <FilePicker
            onDrop={file => {
              setImgFile([file, URL.createObjectURL(file)]);
              uploadMedia(file);
            }}
            buttonLabel="Choose Image"
            inputAccept=".jpg,.jpeg,.png,.avif,.webp"
            dropAcceptExts={['.jpg', '.jpeg', '.png', '.avif', '.webp']}
            containerClassName="tw-py-8"
            maxFileSize={[10_000_000, '10MB']}
          />
        </Collapse>
      )}
      {uploaderStatus.kind === 'done' ? (
        <div>
          <Button
            block
            className="tw-mt-4"
            disabled={updateInFlight}
            onClick={() => {
              setUpdateInFlight(true);
              apiFeedSetImage(
                { feed_id: props.feed.feed_id, media_upload_id: uploaderStatus.uploadId },
                {
                  onFinally: () => setUpdateInFlight(false),
                  onResult: res => {
                    okToast('Updated!');
                    dispatch(MainActionCreators.updateFeed(res));
                    setImgFile(null);
                    setReplaceMode(false);
                    uploaderReset();
                  },
                },
              );
            }}
          >
            <div className="tw-flex tw-items-center tw-justify-center">
              <span className="tw-mr-2">Set Picture</span>
              <Spinner.Presence>{updateInFlight ? <Spinner color="light" /> : null}</Spinner.Presence>
            </div>
          </Button>
        </div>
      ) : null}
      {agentMode && props.feed.type['.tag'] === 'rss' ? (
        <div className="tw-mt-4">
          <ProfilePictureAutoDeposit feed={props.feed} />
        </div>
      ) : null}
    </div>
  );
};

const ProfilePictureAutoDeposit = (props: { feed: api.feed.IFeedInfo }) => {
  const dispatch = useDispatch();
  const apiClient = useAuthedApiClient();
  const { apiDo: apiMedialibAutoDeposit, errToast } = useApiDo(apiClient, apiClient.medialibAutoDeposit);
  const { apiDo: apiFeedSetImage } = useApiDo(apiClient, apiClient.feedSetImage);
  const [inFlight, setInFlight] = React.useState(false);
  const [depositRes, setDepositRes] = React.useState<api.medialib.IAutoDepositResult | null>(null);
  return (
    <div>
      <div>
        <span className="tw-font-semibold">Agent: Auto Deposit</span>
      </div>
      {depositRes ? (
        <div>
          <img src={depositRes.deposit_result.temp_media_url} width={120} height={120} />
        </div>
      ) : null}
      <div className="tw-flex tw-gap-2">
        <Button
          disabled={inFlight || depositRes !== null}
          onClick={() => {
            setInFlight(true);
            apiMedialibAutoDeposit(
              { feed_id: props.feed.feed_id },
              {
                onFinally: () => setInFlight(false),
                onResult: res => {
                  setDepositRes(res);
                },
                onRouteErr: (res, defaultErrToast) => {
                  if (res['.tag'] === 'no_suggestion') {
                    errToast('No image found');
                  } else {
                    defaultErrToast();
                  }
                },
              },
            );
          }}
        >
          Detect Deposit
        </Button>
        <Button
          disabled={inFlight || depositRes === null}
          onClick={() => {
            if (!depositRes) {
              return;
            }
            setInFlight(true);
            apiFeedSetImage(
              { feed_id: props.feed.feed_id, media_upload_id: depositRes.media_upload_id },
              {
                onFinally: () => setInFlight(false),
                onResult: res => {
                  dispatch(MainActionCreators.updateFeed(res));
                  setDepositRes(null);
                },
              },
            );
          }}
        >
          Use Deposit
        </Button>
      </div>
    </div>
  );
};

export default ProfilePictureUpdate;
