import clsx from 'clsx';
import { AnimatePresence, m } from 'framer-motion';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { router } from '../index';
import { MainActionCreators } from '../state/reducer';
import { IAppState, IToast } from '../state/store';
import { getSearchParameter, removeSearchParameter } from '../util';
import ArchiveIcon from './icon/ArchiveIcon';
import CheckIcon from './icon/CheckIcon';
import DumbbellIcon from './icon/DumbbellIcon';
import FaceFrownIcon from './icon/FaceFrown';
import InfoIcon from './icon/InfoIcon';
import RefreshIcon from './icon/RefreshIcon';
import SaveIcon from './icon/SaveIcon';

import { useKeyPress } from './KeyPressContext';

const ToastGroup = () => {
  const dispatch = useDispatch();
  const navigate = router.navigate;
  useEffect(() => {
    const alertParam = getSearchParameter(location.search, 'alert');
    if (alertParam) {
      let msg = alertParam;
      if (alertParam === 'email_verified') {
        msg = 'Your email was verified';
      } else if (alertParam === 'logout') {
        msg = 'Bye bye!';
      } else if (alertParam === 'new_user') {
        msg = 'Welcome!';
      } else if (alertParam === 'reset_password') {
        msg = 'Your password has been reset';
      }
      dispatch(
        MainActionCreators.addToast({
          header: msg,
          icon: 'info',
        }),
      );
      navigate(window.location.pathname + removeSearchParameter(window.location.search, 'alert'), { replace: true });
    }
  }, []);

  const toast = useSelector<IAppState, IToast | null>(state => state.toast);
  return (
    // By setting left AND right, the inner toast can set a full-width for mobile
    // screens.
    <div className="tw-fixed tw-bottom-4 tw-left-4 tw-right-4 tw-z-[1070]">
      <AnimatePresence>{toast ? <ToastBox key={toast.id} toast={toast} /> : null}</AnimatePresence>
    </div>
  );
};

const ToastBox = React.memo(
  (props: { toast: IToast }) => {
    const actioned = React.useRef(false);
    const dispatch = useDispatch();

    const dismissToast = () => {
      dispatch(MainActionCreators.removeToast(props.toast.id));
    };

    useEffect(() => {
      const timeoutId = setTimeout(() => dismissToast(), props.toast.action ? 5000 : 2000);
      return () => {
        clearTimeout(timeoutId);
        if (!actioned.current && props.toast.onTtlDismiss) {
          props.toast.onTtlDismiss();
        }
      };
    }, []);

    useKeyPress(
      'z',
      () => {
        if (props.toast.action) {
          dismissToast();
          actioned.current = true;
          props.toast.action.fn();
        }
      },
      !props.toast.action?.zKeyShortcut,
    );

    const icon =
      props.toast.icon === 'archive' ? (
        <ArchiveIcon size="1.3rem" offsetUp />
      ) : props.toast.icon === 'dumbbell' ? (
        <DumbbellIcon size="1.3rem" offsetUp />
      ) : props.toast.icon === 'redo' ? (
        <RefreshIcon size="1.3rem" offsetUp />
      ) : props.toast.icon === 'info' ? (
        <InfoIcon size="1.3rem" offsetUp />
      ) : props.toast.icon === 'check' ? (
        <CheckIcon size="1.3rem" offsetUp />
      ) : props.toast.icon === 'frown' ? (
        <FaceFrownIcon size="1.3rem" offsetUp />
      ) : props.toast.icon === 'save' ? (
        <SaveIcon size="1.3rem" offsetUp />
      ) : null;

    return (
      <m.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.2 }}
        exit={{ opacity: 0, scale: 0.5 }}
        className={clsx(
          // Use the full width on mobile only.
          'tw-w-full sm:tw-basis-[14rem] sm:tw-min-w-[10rem] sm:tw-max-w-[21rem]',
          'tw-border tw-border-solid tw-border-slate-800/10 dark:tw-border-slate-100/10 tw-rounded-lg',
          'tw-overflow-hidden', // inner divs won't overflow rounded corners
          'tw-shadow',
        )}
      >
        {props.toast.header ? (
          <div
            className={clsx(
              'tw-flex tw-items-center tw-justify-between tw-bg-clip-padding',
              'tw-py-2 tw-px-4 tw-bg-primary-inverted tw-text-primary-inverted tw-text-base',
              'tw-border tw-border-x-0 tw-border-t-0 tw-border-solid tw-border-layout-line-dark dark:tw-border-layout-line-light',
            )}
          >
            <strong>
              {props.toast.icon ? <span className="tw-mr-2">{icon}</span> : null}
              {props.toast.header}
            </strong>
            {props.toast.action ? (
              <strong
                role="button"
                // left padding prevents encroachment from header text
                className="tw-pl-6 tw-text-link-blue"
                onClick={() => {
                  dismissToast();
                  if (props.toast.action) {
                    actioned.current = true;
                    props.toast.action.fn();
                  }
                }}
              >
                {props.toast.action.label}
              </strong>
            ) : null}
          </div>
        ) : null}
        {props.toast.body ? (
          <div className="tw-p-3 tw-bg-highlight-inverted tw-text-primary-inverted">
            <span>{props.toast.body}</span>
          </div>
        ) : null}
      </m.div>
    );
  },
  (prevProps, nextProps) => prevProps.toast === nextProps.toast,
);

export default ToastGroup;
