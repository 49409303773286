import clsx from 'clsx';
import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import React from 'react';

import { Image as ImageNode, Link as LinkNode, Paragraph as ParagraphNode } from 'mdast';
import { toMarkdown } from 'mdast-util-to-markdown';
import remarkParse from 'remark-parse';
import { unified } from 'unified';
import { useImmer } from 'use-immer';

import Config from '../config';

import EgoMarkdown from './EgoMarkdown';
import FeedEntryItemEmbed from './FeedEntryItemEmbed';
import FeedEntrySearchInput from './FeedEntrySearchInput';
import FeedItemEmbed from './FeedItemEmbed';
import FeedSearchInput from './FeedSearchInput';
import useAgentMode from './hooks/useAgentMode';
import { useAuthedApiClient } from './hooks/useApiClient';
import useMediaUploader, { getFileContentType, UploaderStatus } from './hooks/useMediaUploader';
import useNav from './hooks/useNav';
import useToast from './hooks/useToast';
import CloseCircleIcon from './icon/CloseCircleIcon';
import ImageIcon from './icon/ImageIcon';
import PlusIcon from './icon/PlusIcon';
import PrexoIcon from './icon/PrexoIcon';
import TextIcon from './icon/TextIcon';
import TwitterIcon from './icon/TwitterIcon';
import VideoIcon from './icon/VideoIcon';
import YouTubeIcon from './icon/YouTubeIcon';
import Alert from './lib/Alert';
import Badge from './lib/Badge';
import Button from './lib/Button';
import Collapse from './lib/Collapse';
import Fade from './lib/Fade';
import FilePicker from './lib/FilePicker';
import InputLabel from './lib/InputLabel';
import Spinner from './lib/Spinner';
import TextInput, { TextInputFooterForOptional } from './lib/TextInput';
import type { MarkdownInputMethods } from './MarkdownInput';
import TwitterTweet from './TwitterTweet';
import VideoEmbed, { VideoEmbedFromSpec } from './VideoEmbed';
import YouTubeEmbed from './YouTubeEmbed';

const MarkdownInput = React.lazy(() => import('./MarkdownInput'));

//
// Posts are made of boxes.
// These are the different box types.
//

interface AppVideoBox {
  kind: 'app-video';
  boxId: string;
  uploadId?: string;
}

interface AppVideoReifiedBox {
  kind: 'app-video-reified';
  boxId: string;
  node: LinkNode;
}

interface EntryBox {
  kind: 'entry';
  boxId: string;
  url: string;
  entryId?: string;
}

interface FeedBox {
  kind: 'feed';
  boxId: string;
  url: string;
  feedId?: string;
}

interface ImageBox {
  kind: 'image';
  boxId: string;
  uploadId?: string;
  tempMediaUrl?: string;
  caption?: string;
}

interface ImageReifiedBox {
  kind: 'image-reified';
  boxId: string;
  node: ImageNode;
}

interface SlideDividerBox {
  kind: 'slide-divider';
  boxId: string;
}

interface TextBox {
  kind: 'text';
  boxId: string;
  initContent: string;
}

interface TweetBox {
  kind: 'tweet';
  boxId: string;
  url: string;
  tweetId?: string;
}

interface YouTubeBox {
  kind: 'youtube';
  boxId: string;
  url: string;
  videoId?: string;
}

export type Box = (
  | AppVideoBox
  | AppVideoReifiedBox
  | EntryBox
  | FeedBox
  | ImageBox
  | ImageReifiedBox
  | SlideDividerBox
  | TextBox
  | TweetBox
  | YouTubeBox
) & { error?: boolean };

// --

const AddBoxOption = (props: { title: string; icon?: React.ReactNode; onPress: () => void }) => (
  <Button variant="secondary" sm onClick={props.onPress} className="tw-h-[5rem] tw-w-[5rem]">
    <div className="tw-flex tw-flex-col tw-items-center tw-gap-y-2">
      {props.icon ? <div className="tw-h-[2rem] tw-w-[2rem]">{props.icon}</div> : null}
      <span className="tw-whitespace-normal">{props.title}</span>
    </div>
  </Button>
);

const AddBoxOptions = (props: {
  textOk?: boolean;
  imageOk?: boolean;
  videoOk?: boolean;
  addBox: (newBox: Box) => void;
  slideDividerOk?: boolean;
  last?: boolean;
  permanentlyOpen?: boolean;
}) => {
  const [expand, setExpand] = React.useState(!!props.permanentlyOpen);
  return (
    <div>
      {!props.permanentlyOpen ? (
        <div className="tw-flex tw-justify-center tw-mb-2">
          <div className="tw-relative tw-h-[2rem] tw-border-l-2 tw-border-l-layout-line-light dark:tw-border-l-layout-line-dark">
            <div className="tw-absolute tw-top-[0.125rem] tw-left-[0.25rem]">
              <Button variant="secondary" sm onClick={() => setExpand(!expand)}>
                <PlusIcon size="1rem" />
              </Button>
            </div>
          </div>
        </div>
      ) : null}
      <Collapse open={expand}>
        <div className="tw-flex tw-justify-center tw-flex-wrap tw-gap-2">
          {props.textOk ? (
            <AddBoxOption
              title="Text"
              icon={<TextIcon size="1.9rem" />}
              onPress={() => {
                props.addBox({ boxId: mkBoxId(), initContent: '', kind: 'text' });
                setExpand(false);
              }}
            />
          ) : null}
          {props.imageOk ? (
            <AddBoxOption
              title="Image"
              icon={<ImageIcon size="2rem" />}
              onPress={() => {
                props.addBox({ boxId: mkBoxId(), kind: 'image' });
                setExpand(false);
              }}
            />
          ) : null}
          {props.videoOk ? (
            <AddBoxOption
              title="Video"
              icon={<VideoIcon size="2rem" />}
              onPress={() => {
                props.addBox({ boxId: mkBoxId(), kind: 'app-video' });
                setExpand(false);
              }}
            />
          ) : null}
          <AddBoxOption
            title="YouTube"
            icon={<YouTubeIcon size="2.1rem" colorOverride="" />}
            onPress={() => {
              props.addBox({ boxId: mkBoxId(), kind: 'youtube', url: '' });
              setExpand(false);
            }}
          />
          <AddBoxOption
            title="Tweet"
            icon={<TwitterIcon size="2rem" />}
            onPress={() => {
              props.addBox({ boxId: mkBoxId(), kind: 'tweet', url: '' });
              setExpand(false);
            }}
          />
          <AddBoxOption
            title="Feed"
            onPress={() => {
              props.addBox({ boxId: mkBoxId(), kind: 'feed', url: '' });
              setExpand(false);
            }}
          />
          <AddBoxOption
            title="Post"
            onPress={() => {
              props.addBox({ boxId: mkBoxId(), kind: 'entry', url: '' });
              setExpand(false);
            }}
          />
          {props.slideDividerOk ? (
            <AddBoxOption
              title="Slide Divider"
              onPress={() => {
                props.addBox({ boxId: mkBoxId(), kind: 'slide-divider' });
                setExpand(false);
              }}
            />
          ) : null}
        </div>
        {!props.last ? (
          <div className="tw-flex tw-justify-center tw-my-2 tw-group">
            <div className="tw-relative tw-h-[2rem] tw-border-l-2 tw-border-l-layout-line-light dark:tw-border-l-layout-line-dark"></div>
          </div>
        ) : null}
      </Collapse>
    </div>
  );
};

const mkBoxId = (length: number = 10): string => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  return Array.from({ length }, () => chars.charAt(Math.floor(Math.random() * chars.length))).join('');
};

//
// Type for reporting the result of the editor to the PostModal.
//

interface BoxMarkdownReady {
  kind: 'ready';
  content: string;
}

// Media hasn't finished uploading
interface BoxMarkdownNotReady {
  kind: 'not-ready';
}

// A box has a bad specification that the user must correct
interface BoxMarkdownNeedFix {
  kind: 'need-fix';
}

type BoxMarkdownResult = BoxMarkdownNotReady | BoxMarkdownReady | BoxMarkdownNeedFix;

// --

interface PostBoxesEditorProps {
  prexoOk?: boolean;
  initMarkdownContent?: string;
  contentVideoSpecs?: api.feed.IContentVideoSpec[];
  maxBoxes?: number;
  noBoxesWithMediaUpload?: boolean;
  topTextBoxContentChanged?: (content: string) => void;
  setBoxesOnMajorChange?: (val: Box[]) => void;
}

export interface PostBoxesEditorMethods {
  getMarkdownContent: () => BoxMarkdownResult;
  isTopTextBoxEmpty: () => boolean;
  setTopTextBoxContent: (newTextContent: string) => boolean;
}

/**
 * PostBoxesEditor can be pretty hefty because of its use of MarkdownInput.
 * WARNING: To maximize performance, it's very important than the editor does
 * NOT re-render often. For example, re-rendering on every key entered into a
 * MarkdownInput makes the editor unbearably slow.
 *
 * The memoization allows parents to set (cached) handlers for top-box & major-
 * box changes without necessarily re-rendering.
 */
const PostBoxesEditor = React.memo(
  React.forwardRef<PostBoxesEditorMethods, PostBoxesEditorProps>((props, ref) => {
    const agentMode = useAgentMode();
    const apiClient = useAuthedApiClient();
    const { navToFeed } = useNav();
    const { setToast } = useToast();
    const [boxes, updateBoxes] = useImmer<Box[]>([{ boxId: mkBoxId(), initContent: '', kind: 'text' }]);
    // Similar to boxes, but only contains major changes to boxes.
    // For example, each box has a matching identity. However, TextBox objs do
    // not have content populated. This allows for more efficient listening by
    // parent components.
    const [shadowBoxes, updateShadowBoxes] = useImmer<Box[]>(boxes);
    React.useEffect(() => {
      if (props.initMarkdownContent) {
        const initBoxes = convertInitMarkdownContentToBoxes(props.initMarkdownContent);
        updateBoxes(initBoxes);
        updateShadowBoxes(initBoxes);
        if (initBoxes.length > 0) {
          const topBox = initBoxes[0];
          if (topBox.kind === 'text') {
            if (props.topTextBoxContentChanged) {
              props.topTextBoxContentChanged(topBox.initContent);
            }
          }
        }
      }
    }, []);
    React.useEffect(() => {
      if (props.setBoxesOnMajorChange) {
        props.setBoxesOnMajorChange(shadowBoxes);
      }
    }, [shadowBoxes]);

    const addBox = React.useCallback((afterBoxId: string | null, newBox: Box) => {
      updateBoxes(draft => {
        if (afterBoxId) {
          const index = draft.findIndex(box => box.boxId === afterBoxId);
          if (index >= 0) {
            draft.splice(index + 1, 0, newBox);
          }
        } else {
          draft.splice(0, 0, newBox);
        }
      });
      updateShadowBoxes(draft => {
        if (afterBoxId) {
          const index = draft.findIndex(box => box.boxId === afterBoxId);
          if (index >= 0) {
            draft.splice(index + 1, 0, newBox);
          }
        } else {
          draft.splice(0, 0, newBox);
        }
      });
    }, []);
    const removeBox = React.useCallback((boxId: string, trailingBoxCount: number = 0) => {
      updateBoxes(draft => {
        const index = draft.findIndex(box => box.boxId === boxId);
        if (index >= 0) {
          draft.splice(index, 1 + trailingBoxCount);
        }
      });
      updateShadowBoxes(draft => {
        const index = draft.findIndex(box => box.boxId === boxId);
        if (index >= 0) {
          draft.splice(index, 1 + trailingBoxCount);
        }
      });
    }, []);

    React.useImperativeHandle(
      ref,
      () => ({
        getImageBoxes: () => boxes.filter(box => box.kind === 'image') as ImageBox[],
        getMarkdownContent: () => {
          updateBoxes(draft => {
            for (const boxToUpdate of draft) {
              boxToUpdate.error = false;
            }
          });
          for (const box of boxes) {
            if (box.kind === 'app-video' || box.kind === 'image') {
              const boxRef = boxRefs.current.get(box.boxId);
              if (boxRef) {
                const uploaderStatus = boxRef.getUploaderStatus();
                if (uploaderStatus.kind === 'uploading') {
                  updateBoxes(draft => {
                    for (const boxToUpdate of draft) {
                      if (boxToUpdate.boxId === box.boxId) {
                        boxToUpdate.error = true;
                        break;
                      }
                    }
                  });
                  return { kind: 'not-ready' };
                } else if (uploaderStatus.kind === 'error') {
                  updateBoxes(draft => {
                    for (const boxToUpdate of draft) {
                      if (boxToUpdate.boxId === box.boxId) {
                        boxToUpdate.error = true;
                        break;
                      }
                    }
                  });
                  return { kind: 'need-fix' };
                }
              }
            }
          }
          const intermediateBoxes: Array<
            | AppVideoReifiedBox
            | EntryBox
            | FeedBox
            | ImageReifiedBox
            | SlideDividerBox
            | TextBox
            | TweetBox
            | YouTubeBox
          > = boxes
            .map(
              (
                box,
              ):
                | AppVideoReifiedBox
                | EntryBox
                | FeedBox
                | ImageReifiedBox
                | SlideDividerBox
                | TextBox
                | TweetBox
                | YouTubeBox
                | null => {
                if (box.kind === 'text') {
                  return box as TextBox;
                } else if (box.kind === 'slide-divider') {
                  return box as SlideDividerBox;
                } else if (box.kind === 'youtube') {
                  return box as YouTubeBox;
                } else if (box.kind === 'tweet') {
                  return box as TweetBox;
                } else if (box.kind === 'feed') {
                  return box as FeedBox;
                } else if (box.kind === 'entry') {
                  return box as EntryBox;
                } else if (box.kind === 'app-video') {
                  if (box.uploadId) {
                    const linkNode: LinkNode = {
                      children: [{ type: 'text', value: '' }],
                      type: 'link',
                      url: `upload_id:${box.uploadId}`,
                    };
                    return {
                      boxId: box.boxId,
                      kind: 'app-video-reified',
                      node: linkNode,
                    };
                  } else {
                    return null;
                  }
                } else if (box.kind === 'app-video-reified') {
                  return box as AppVideoReifiedBox;
                } else if (box.kind === 'image') {
                  if (box.uploadId) {
                    const imageNode: ImageNode = {
                      alt: `upload_id:${box.uploadId}`,
                      title: box.caption,
                      type: 'image',
                      url: box.tempMediaUrl ?? '',
                    };
                    return {
                      boxId: box.boxId,
                      kind: 'image-reified',
                      node: imageNode,
                    } as ImageReifiedBox;
                  } else {
                    return null;
                  }
                } else if (box.kind === 'image-reified') {
                  return box as ImageReifiedBox;
                } else {
                  return null;
                }
              },
            )
            .filter(
              (
                box:
                  | AppVideoReifiedBox
                  | EntryBox
                  | FeedBox
                  | ImageReifiedBox
                  | SlideDividerBox
                  | TextBox
                  | TweetBox
                  | YouTubeBox
                  | null,
              ) => box !== null,
            ) as Array<EntryBox | FeedBox | ImageReifiedBox | SlideDividerBox | TextBox | TweetBox | YouTubeBox>;
          let finalContent = '';
          let prevBox:
            | AppVideoReifiedBox
            | EntryBox
            | FeedBox
            | ImageReifiedBox
            | SlideDividerBox
            | TextBox
            | TweetBox
            | YouTubeBox
            | null = null;
          for (const box of intermediateBoxes) {
            if (box.kind === 'text') {
              const textBoxRef = textBoxRefs.current.get(box.boxId);
              if (!textBoxRef) {
                throw Error('Unexpected missing ref for box');
              }
              const textBoxResult = textBoxRef.getMarkdown();
              if (textBoxResult.tooLong) {
                updateBoxes(draft => {
                  for (const boxToUpdate of draft) {
                    if (boxToUpdate.boxId === box.boxId) {
                      boxToUpdate.error = true;
                      break;
                    }
                  }
                });
                return {
                  kind: 'need-fix',
                };
              } else if (textBoxResult.content.length === 0) {
                // This empty text box was used as a delimiter to separate images to
                // prevent them from being grouped into a slider.
              } else if (prevBox !== null) {
                finalContent += '\n\n' + textBoxResult.content;
              } else {
                finalContent += textBoxResult.content;
              }
            } else if (box.kind === 'entry' || box.kind === 'feed' || box.kind === 'tweet' || box.kind === 'youtube') {
              if (box.url) {
                finalContent += '\n\n' + `[](${box.url})`;
              }
            } else if (box.kind === 'app-video-reified') {
              const linkMarkdown = toMarkdown({
                children: [box.node],
                type: 'root',
              }).trim(); // trim because it renders a paragraph which includes a newline
              finalContent += '\n\n' + linkMarkdown;
            } else if (box.kind === 'image-reified') {
              const imageMarkdown = toMarkdown({
                children: [box.node],
                type: 'root',
              }).trim(); // trim because it renders a paragraph which includes a newline
              if (prevBox) {
                if (prevBox.kind === 'image-reified') {
                  finalContent += '\n' + imageMarkdown;
                } else {
                  finalContent += '\n\n' + imageMarkdown;
                }
              } else {
                finalContent += imageMarkdown;
              }
            } else if (box.kind === 'slide-divider') {
              finalContent += '\n\n' + '[//]: # (slide)';
            } else {
              throw Error('Unexpected box type');
            }
            prevBox = box;
          }
          return {
            content: finalContent,
            kind: 'ready',
          };
        },
        isTopTextBoxEmpty: () => {
          const topBox = boxes[0];
          if (topBox.kind !== 'text') {
            throw Error('Unexpected box type');
          }
          const textBoxRef = textBoxRefs.current.get(topBox.boxId);
          if (!textBoxRef) {
            throw Error('Unexpected missing box ref');
          }
          return textBoxRef.getMarkdown().content.length === 0;
        },
        setTopTextBoxContent: (newTextContent: string): boolean => {
          const topBox = boxes[0];
          if (topBox.kind === 'text') {
            const textBoxRef = textBoxRefs.current.get(topBox.boxId);
            if (!textBoxRef) {
              if (textBoxRefs.current.size === 0) {
                // This case occurs if this fn is called before the text-box
                // has been loaded. While it's not ideal, ignore the text and
                // use a return value to inform the caller.
                return false;
              } else {
                throw Error('Unexpected missing ref for top box');
              }
            }
            textBoxRef.setMarkdown(newTextContent);
            if (props.topTextBoxContentChanged) {
              props.topTextBoxContentChanged(newTextContent);
            }
            return true;
          } else {
            return false;
          }
        },
      }),
      [boxes],
    );
    const videoInfoMap: Map<string, api.feed.IContentVideoSpec> = new Map();
    if (props.contentVideoSpecs) {
      for (const videoInfo of props.contentVideoSpecs) {
        videoInfoMap.set(videoInfo.url, videoInfo);
      }
    }

    const textBoxRefs = React.useRef<Map<string, MarkdownInputMethods>>(new Map());
    // Used to query applicable boxes for upload status
    const boxRefs = React.useRef<Map<string, AppVideoUploadBoxMethods>>(new Map());

    const isPrexo = boxes.filter(box => box.kind === 'slide-divider').length > 0;

    interface PrexoSlideUI {
      // null if it's not a prexo
      slideDividerBoxId: string | null;
      boxes: Array<[number, Box]>;
    }

    const prexoSlides: PrexoSlideUI[] = [];
    if (isPrexo) {
      let boxIndex = 0;
      let curSlide: PrexoSlideUI | null = null;
      for (const box of boxes) {
        if (curSlide === null) {
          if (box.kind === 'slide-divider') {
            curSlide = { slideDividerBoxId: box.boxId, boxes: [] };
          } else {
            boxIndex += 1;
            // Skips junk like empty text boxes at the top
            continue;
          }
        } else {
          if (box.kind === 'slide-divider') {
            prexoSlides.push(curSlide);
            curSlide = { slideDividerBoxId: box.boxId, boxes: [] };
          } else {
            curSlide.boxes.push([boxIndex, box]);
          }
        }
        boxIndex += 1;
      }
      if (curSlide !== null) {
        prexoSlides.push(curSlide);
      }
    } else {
      prexoSlides.push({
        boxes: boxes.map((box, index) => [index, box]),
        slideDividerBoxId: null,
      });
    }

    return (
      <div className="tw-flex tw-flex-col tw-gap-y-8">
        {props.prexoOk &&
        !isPrexo &&
        prexoSlides.length === 1 &&
        prexoSlides[0].boxes.length === 1 &&
        prexoSlides[0].boxes[0][1].kind === 'text' ? (
          <Alert variant="info" className="tw-flex tw-flex-col tw-gap-y-2">
            <Alert.Heading className="tw-text-xl">
              Try <PrexoIcon size="1.25rem" className="tw-mx-2" /> Prexo Post?
            </Alert.Heading>
            <div>
              A prexo is a collection of slides (text, images, videos) that can be swiped through. It's great for
              engaging content that can ingested at the reader's own pace.
            </div>
            <div>
              <Button sm onClick={() => addBox(null, { boxId: mkBoxId(), kind: 'slide-divider' })}>
                Switch to <PrexoIcon size="1.25rem" className="tw-mx-2" /> Prexo-style Post
              </Button>
            </div>
          </Alert>
        ) : null}
        <div className="tw-flex tw-flex-col tw-gap-y-4">
          <Fade.Presence>
            {prexoSlides.map(({ boxes: slideBoxes, slideDividerBoxId }, slideIndex) => (
              <MaybePrexoSlide
                key={slideDividerBoxId ?? slideIndex}
                isPrexoSlide={isPrexo}
                slideIndex={slideIndex}
                removeSlide={
                  slideDividerBoxId && prexoSlides.length > 1
                    ? () => {
                        if (slideDividerBoxId) {
                          removeBox(slideDividerBoxId, slideBoxes.length);
                        }
                      }
                    : undefined
                }
                newSlideBelow={
                  isPrexo && slideBoxes.length > 0
                    ? () =>
                        addBox(slideBoxes[slideBoxes.length - 1][1].boxId, { boxId: mkBoxId(), kind: 'slide-divider' })
                    : undefined
                }
              >
                <Fade.Presence>
                  {slideBoxes.map(([index, box], relativeSlideBoxIndex) => (
                    <Fade key={box.boxId} open initialOpen={index === 0}>
                      <div className="tw-flex tw-flex-col tw-gap-y-2">
                        <div
                          className={clsx(
                            'tw-flex tw-flex-col tw-gap-y-2 tw-bg-highlight tw-rounded-md',
                            'tw-pb-4',
                            index === 0 ? 'tw-pt-7' : 'tw-pt-2',
                            box.error ? 'tw-border tw-border-red-700' : null,
                          )}
                        >
                          <div className="tw-flex tw-flex-row-reverse tw-gap-x-2 tw-ml-5 tw-mr-2 ">
                            {index !== 0 ? (
                              <div role="button" onClick={() => removeBox(box.boxId)}>
                                <CloseCircleIcon size="1.5rem" className="tw-text-muted" />
                              </div>
                            ) : null}
                            <span className="tw-font-semibold tw-self-end">
                              {index !== 0
                                ? box.kind === 'text'
                                  ? 'Text'
                                  : box.kind === 'app-video' || box.kind === 'app-video-reified'
                                    ? 'Video'
                                    : box.kind === 'tweet'
                                      ? 'X Post'
                                      : box.kind === 'image' || box.kind === 'image-reified'
                                        ? 'Image'
                                        : box.kind === 'youtube'
                                          ? 'YouTube Video'
                                          : box.kind === 'entry'
                                            ? 'Post'
                                            : box.kind === 'feed'
                                              ? 'Feed'
                                              : null
                                : null}
                            </span>
                          </div>
                          {box.kind === 'text' ? (
                            <div className="tw-px-4 tw-flex tw-flex-col">
                              <React.Suspense fallback={<Spinner show lg className="tw-self-center" />}>
                                <MarkdownInput
                                  ref={boxRef => {
                                    if (boxRef) {
                                      textBoxRefs.current.set(box.boxId, boxRef);
                                    }
                                  }}
                                  initMarkdown={box.initContent}
                                  onChange={newTextContent => {
                                    if (index === 0 && boxes[index].kind === 'text') {
                                      if (props.topTextBoxContentChanged) {
                                        props.topTextBoxContentChanged(newTextContent);
                                      }
                                    }
                                  }}
                                  maxLength={10_000}
                                  includeSourceDiff={agentMode}
                                />
                              </React.Suspense>
                            </div>
                          ) : box.kind === 'slide-divider' ? (
                            <div className="tw-px-4 tw-flex tw-items-center">
                              <div className="tw-flex tw-flex-grow tw-border-t-2 tw-border-solid tw-border-gray-800 dark:tw-border-gray-100" />
                              <div className="tw-font-bold tw-mx-4">slide divider</div>
                              <div className="tw-flex tw-flex-grow tw-border-t-2 tw-border-solid tw-border-gray-800 dark:tw-border-gray-100" />
                            </div>
                          ) : box.kind === 'youtube' ? (
                            <div className="tw-px-4 tw-flex tw-flex-col tw-gap-y-4">
                              <TextInput
                                placeholder="e.g. www.youtube.com/watch?v=dQw4w9WgXcQ"
                                onChange={e => {
                                  const newUrl = e.currentTarget.value;
                                  updateBoxes(draft => {
                                    const boxToUpdate = draft[index];
                                    if (boxToUpdate.kind === 'youtube') {
                                      boxToUpdate.url = newUrl;
                                      boxToUpdate.videoId =
                                        cfe.ApiHelpers.parseYouTubeVideoIdFromUrl(newUrl) ?? undefined;
                                    }
                                  });
                                }}
                                value={box.url}
                                maxLength={100}
                              />
                              {box.videoId ? <YouTubeEmbed ytVideoId={box.videoId} /> : null}
                            </div>
                          ) : box.kind === 'tweet' ? (
                            <div className="tw-px-4 tw-flex tw-flex-col tw-gap-y-4">
                              <TextInput
                                placeholder="e.g. https://twitter.com/x/status/1454507672"
                                onChange={e => {
                                  const newUrl = e.currentTarget.value;
                                  updateBoxes(draft => {
                                    const boxToUpdate = draft[index];
                                    if (boxToUpdate.kind === 'tweet') {
                                      boxToUpdate.url = newUrl;
                                      boxToUpdate.tweetId = cfe.ApiHelpers.parseTwitterTweetId(newUrl) ?? undefined;
                                    }
                                  });
                                }}
                                value={box.url}
                                maxLength={100}
                              />
                              {box.tweetId ? <TwitterTweet tweetId={box.tweetId} center /> : null}
                            </div>
                          ) : box.kind === 'entry' ? (
                            <div className="tw-flex tw-flex-col tw-gap-y-4 tw-mt-2">
                              {box.entryId ? (
                                <div className="tw-px-4">
                                  <FeedEntryItemEmbed
                                    key={box.entryId}
                                    apiClient={apiClient}
                                    navToFeed={navToFeed}
                                    entryId={box.entryId}
                                  />
                                </div>
                              ) : (
                                <FeedEntrySearchInput
                                  closeOnSelect
                                  gutterClassName="tw-px-4"
                                  onSelect={(_, entry) => {
                                    const newUrl =
                                      window.origin + cfe.ApiHelpers.getUrlPathnameForEntry(entry.entry_id);
                                    updateBoxes(draft => {
                                      const boxToUpdate = draft[index];
                                      if (boxToUpdate.kind === 'entry') {
                                        boxToUpdate.url = newUrl;
                                        boxToUpdate.entryId =
                                          cfe.PostHelpers.parseFeedEntryIdFromUrl(newUrl, Config.wwwHost) ?? undefined;
                                      }
                                    });
                                  }}
                                />
                              )}
                            </div>
                          ) : box.kind === 'feed' ? (
                            <div>
                              {box.feedId ? (
                                <div className="tw-px-4">
                                  <FeedItemEmbed feedId={box.feedId} showProfileImage="yes-if-available" />
                                </div>
                              ) : (
                                <FeedSearchInput
                                  kbActive={false}
                                  onClickOverride={feed => {
                                    const newUrl = `${Config.wwwHost}/f/${feed.feed_id}`;
                                    updateBoxes(draft => {
                                      const boxToUpdate = draft[index];
                                      if (boxToUpdate.kind === 'feed') {
                                        boxToUpdate.url = newUrl;
                                        boxToUpdate.feedId = feed.feed_id;
                                      }
                                    });
                                  }}
                                  showProfileImage="yes-if-available"
                                />
                              )}
                            </div>
                          ) : box.kind === 'app-video' ? (
                            <div className="tw-px-4">
                              <AppVideoUploadBox
                                ref={boxRef => {
                                  if (boxRef) {
                                    boxRefs.current.set(box.boxId, boxRef);
                                  }
                                }}
                                setUploadId={uploadId => {
                                  updateBoxes(draft => {
                                    const boxToUpdate = draft[index];
                                    if (boxToUpdate.kind === 'app-video') {
                                      boxToUpdate.uploadId = uploadId;
                                    }
                                  });
                                }}
                              />
                            </div>
                          ) : box.kind === 'app-video-reified' && videoInfoMap.has(box.node.url) ? (
                            <div className="tw-px-4">
                              <VideoEmbedFromSpec
                                videoSpec={videoInfoMap.get(box.node.url)!}
                                noOverflow
                                forceNoAutoPlay
                              />
                            </div>
                          ) : box.kind === 'image' ? (
                            <div className="tw-px-4">
                              <ImageUploadBox
                                ref={boxRef => {
                                  if (boxRef) {
                                    boxRefs.current.set(box.boxId, boxRef);
                                  }
                                }}
                                uploadedCallback={(uploadId, tempMediaUrl) => {
                                  updateBoxes(draft => {
                                    const boxToUpdate = draft[index];
                                    if (boxToUpdate.kind === 'image') {
                                      boxToUpdate.uploadId = uploadId;
                                      boxToUpdate.tempMediaUrl = tempMediaUrl;
                                    }
                                  });
                                  updateShadowBoxes(draft => {
                                    const boxToUpdate = draft[index];
                                    if (boxToUpdate.kind === 'image') {
                                      boxToUpdate.uploadId = uploadId;
                                      boxToUpdate.tempMediaUrl = tempMediaUrl;
                                    }
                                  });
                                }}
                              />
                            </div>
                          ) : box.kind === 'image-reified' ? (
                            <div className="tw-px-4">
                              <EgoMarkdown
                                variant="cpc"
                                content={toMarkdown({ type: 'root', children: [box.node] })}
                                goToFeed={() => null}
                              />
                            </div>
                          ) : null}
                          {box.kind === 'image' || box.kind === 'image-reified' ? (
                            <div className="tw-px-4">
                              <div className="tw-mb-4" />
                              <InputLabel>Caption</InputLabel>
                              <TextInput
                                sm
                                type="text"
                                value={box.kind === 'image' ? box.caption : box.node.title ?? ''}
                                onChange={e => {
                                  const val = e.currentTarget.value;
                                  updateBoxes(draft => {
                                    const boxToUpdate = draft[index];
                                    if (boxToUpdate.kind === 'image') {
                                      boxToUpdate.caption = val;
                                    } else if (boxToUpdate.kind === 'image-reified') {
                                      boxToUpdate.node.title = val;
                                    } else {
                                      throw Error('Unexpected box type');
                                    }
                                  });
                                }}
                              />
                              <TextInputFooterForOptional
                                value={(box.kind === 'image' ? box.caption : box.node.title) ?? ''}
                                maxLength={100}
                                hideMaxLengthUntilHalfway
                              />
                            </div>
                          ) : null}
                        </div>
                        {!props.maxBoxes || boxes.length < props.maxBoxes || index !== boxes.length - 1 ? (
                          <AddBoxOptions
                            textOk={box.kind !== 'text'}
                            imageOk={!props.noBoxesWithMediaUpload}
                            videoOk={!props.noBoxesWithMediaUpload}
                            addBox={(newBox: Box) => {
                              if (props.maxBoxes && boxes.length >= props.maxBoxes) {
                                setToast({ header: 'Remove a box before adding another' });
                              } else {
                                addBox(box.boxId, newBox);
                              }
                            }}
                            // FIXME: Make this more restrictive
                            // Require it to be a prexo already!
                            slideDividerOk={agentMode}
                            last={relativeSlideBoxIndex === slideBoxes.length - 1}
                          />
                        ) : null}
                      </div>
                    </Fade>
                  ))}
                  {slideDividerBoxId && slideBoxes.length === 0 ? (
                    <AddBoxOptions
                      textOk
                      imageOk={!props.noBoxesWithMediaUpload}
                      videoOk={!props.noBoxesWithMediaUpload}
                      addBox={(newBox: Box) => {
                        if (props.maxBoxes && boxes.length >= props.maxBoxes) {
                          setToast({ header: 'Remove a box before adding another' });
                        } else {
                          addBox(slideDividerBoxId, newBox);
                        }
                      }}
                      last
                      permanentlyOpen
                    />
                  ) : null}
                </Fade.Presence>
              </MaybePrexoSlide>
            ))}
          </Fade.Presence>
        </div>
      </div>
    );
  }),
  (prevProps, nextProps) => {
    // Ignore initMarkdownContent. It's only used on component creation and
    // expecting called to be disciplined to keep it constant after creation is
    // too unreliable.
    return (
      prevProps.contentVideoSpecs === nextProps.contentVideoSpecs &&
      prevProps.maxBoxes === nextProps.maxBoxes &&
      prevProps.noBoxesWithMediaUpload === nextProps.noBoxesWithMediaUpload &&
      prevProps.topTextBoxContentChanged === nextProps.topTextBoxContentChanged &&
      prevProps.setBoxesOnMajorChange === nextProps.setBoxesOnMajorChange
    );
  },
);

const MaybePrexoSlide = (props: {
  isPrexoSlide: boolean;
  slideIndex: number;
  removeSlide?: () => void;
  newSlideBelow?: () => void;
  children: React.ReactNode;
}) => {
  return props.isPrexoSlide ? (
    <Fade open initialOpen>
      <div className="tw-flex tw-flex-col tw-gap-y-1">
        <div className="tw-flex tw-justify-between tw-items-center">
          <span className="tw-font-bold tw-bg-highlight tw-py-1 tw-px-2 tw-rounded">{props.slideIndex + 1}</span>
          {props.removeSlide ? (
            <div role="button" onClick={props.removeSlide}>
              <CloseCircleIcon size="1.5rem" className="tw-text-muted" />
            </div>
          ) : null}
        </div>
        <div className="tw-rounded-md tw-border-2 tw-border-layout-line-light dark:tw-border-layout-line-dark tw-bg-slate-200 dark:tw-bg-slate-400 tw-p-4">
          {props.children}
        </div>
        {props.newSlideBelow ? (
          <div className="tw-flex tw-justify-center tw-mb-2">
            <div className="tw-relative tw-h-[2rem] tw-border-l-2 tw-border-l-layout-line-light dark:tw-border-l-layout-line-dark">
              <div className="tw-absolute tw-top-[0.125rem] tw-left-[0.25rem]">
                <Button variant="secondary" sm onClick={props.newSlideBelow}>
                  Insert Slide
                </Button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </Fade>
  ) : (
    <>{props.children}</>
  );
};

interface ImageUploadBoxMethods {
  getUploaderStatus: () => UploaderStatus;
}

interface ImageUploadBoxProps {
  uploadedCallback: (uploadId: string, tempMediaUrl?: string) => void;
}

const ImageUploadBox = React.forwardRef<ImageUploadBoxMethods, ImageUploadBoxProps>((props, ref) => {
  const { uploadMedia, uploaderStatus } = useMediaUploader(
    'image',
    { '.tag': 'post_body_image' },
    props.uploadedCallback,
  );
  const [imgFile, setImgFile] = React.useState<[File, string] | null>(null);

  React.useImperativeHandle(ref, () => ({
    getUploaderStatus: () => uploaderStatus,
  }));

  return (
    <div className="tw-flex tw-flex-col">
      {imgFile ? (
        <div className="tw-relative">
          <img
            src={uploaderStatus.kind === 'done' ? uploaderStatus.tempMediaUrl : null ?? imgFile[1]}
            className={clsx('tw-object-cover', uploaderStatus.kind !== 'done' ? 'tw-opacity-30' : null)}
          />
          {uploaderStatus.kind === 'uploading' ? (
            <MediaBadgeContainer>
              <Badge variant="info">{uploaderStatus.progress}%</Badge>
            </MediaBadgeContainer>
          ) : uploaderStatus.kind === 'done' ? (
            <MediaBadgeContainer>
              <Badge variant="success">ready</Badge>
            </MediaBadgeContainer>
          ) : uploaderStatus.kind === 'error' ? (
            <MediaBadgeContainer>
              <Badge variant="danger">error: {uploaderStatus.errMsg}</Badge>
            </MediaBadgeContainer>
          ) : null}
        </div>
      ) : (
        <FilePicker
          onDrop={file => {
            setImgFile([file, URL.createObjectURL(file)]);
            uploadMedia(file);
          }}
          buttonLabel="Choose Image"
          inputAccept=".jpg,.jpeg,.png,.avif,.webp"
          dropAcceptExts={['.jpg', '.jpeg', '.png', '.avif', '.webp']}
          containerClassName="tw-py-8"
          maxFileSize={[10_000_000, '10MB']}
        />
      )}
    </div>
  );
});

interface AppVideoUploadBoxMethods {
  getUploaderStatus: () => UploaderStatus;
}

interface AppVideoUploadBoxProps {
  setUploadId: (uploadId: string) => void;
}

const AppVideoUploadBox = React.forwardRef<AppVideoUploadBoxMethods, AppVideoUploadBoxProps>((props, ref) => {
  const { setToast } = useToast();
  const { uploadMedia, uploaderStatus } = useMediaUploader('video', { '.tag': 'post_body_video' }, props.setUploadId);
  const [videoFile, setVideoFile] = React.useState<[File, string] | null>(null);

  React.useImperativeHandle(ref, () => ({
    getUploaderStatus: () => uploaderStatus,
  }));

  return (
    <div className="tw-flex tw-flex-col">
      {videoFile ? (
        <div className="tw-relative">
          <VideoEmbed
            sources={[
              {
                src: videoFile[1],
                // VideoJS has issues playing video/quicktime, so force type to
                // video/mp4 since they're compatible.
                type: 'video/mp4',
              },
            ]}
            portrait={false}
            noOverflow
            forceNoAutoPlay
          />
          {uploaderStatus.kind === 'uploading' ? (
            <MediaBadgeContainer>
              <Badge variant="info">{uploaderStatus.progress}%</Badge>
            </MediaBadgeContainer>
          ) : uploaderStatus.kind === 'done' ? (
            <MediaBadgeContainer>
              <Badge variant="success">ready</Badge>
            </MediaBadgeContainer>
          ) : uploaderStatus.kind === 'error' ? (
            <MediaBadgeContainer>
              <Badge variant="danger">error: {uploaderStatus.errMsg}</Badge>
            </MediaBadgeContainer>
          ) : null}
        </div>
      ) : (
        <FilePicker
          onDrop={file => {
            const fileType = getFileContentType(file);
            if (fileType === 'video/quicktime' || fileType === 'video/mp4') {
              setVideoFile([file, URL.createObjectURL(file)]);
              uploadMedia(file);
            } else {
              setToast({ header: 'Unsupported format', body: 'Try .mov or .mp4', icon: 'frown' });
            }
          }}
          buttonLabel="Choose Video"
          inputAccept=".mp4,.mov,video/mp4,video/quicktime"
          dropAcceptExts={['.mp4', '.mov']}
          containerClassName="tw-py-8"
          maxFileSize={[1_000_000_000, '1GB']}
        />
      )}
    </div>
  );
});

const MediaBadgeContainer = (props: { children: React.ReactNode }) => (
  <div className="tw-absolute tw-top-1 tw-left-1">{props.children}</div>
);

//
// Conversion is necessary when a post is being updated and therefore starts
// with markdown content that must be converted to boxes.
//

const convertInitMarkdownContentToBoxes = (initContent: string): Box[] => {
  const boxes: Box[] = [];
  const sections = splitMarkdownByDoubleNewlines(initContent);

  // Create a processor with the remark-parse plugin
  const processor = unified().use(remarkParse);

  for (const section of sections) {
    const emptyLink = findEmptyLink(section);
    if (emptyLink) {
      const ytVideoId = cfe.ApiHelpers.parseYouTubeVideoIdFromUrl(emptyLink);
      if (ytVideoId) {
        boxes.push({ boxId: mkBoxId(), kind: 'youtube', url: emptyLink, videoId: ytVideoId });
        continue;
      }
      const tweetId = cfe.ApiHelpers.parseTwitterTweetId(emptyLink);
      if (tweetId) {
        boxes.push({ boxId: mkBoxId(), kind: 'tweet', tweetId, url: emptyLink });
        continue;
      }
      const feedId = cfe.PostHelpers.parseFeedIdFromUrl(emptyLink, Config.wwwHost);
      if (feedId) {
        boxes.push({ boxId: mkBoxId(), feedId, kind: 'feed', url: emptyLink });
        continue;
      }
      const entryId = cfe.PostHelpers.parseFeedEntryIdFromUrl(emptyLink, Config.wwwHost);
      if (entryId) {
        boxes.push({ boxId: mkBoxId(), entryId, kind: 'entry', url: emptyLink });
        continue;
      }
      if (!cfe.ApiHelpers.isUrlOpenable(emptyLink)) {
        const appVideoLinkNode: LinkNode = {
          children: [{ type: 'text', value: '' }],
          type: 'link',
          url: emptyLink,
        };
        boxes.push({ boxId: mkBoxId(), kind: 'app-video-reified', node: appVideoLinkNode });
      }
      continue;
    }
    const imgs = findMarkdownImages(section);
    if (imgs.length > 0) {
      if (boxes.length > 0 && boxes[boxes.length - 1].kind === 'image-reified') {
        // Two images that were separated by double new lines indicates that
        // there was an empty text spacer used in between them combining into a
        // single slider.
        boxes.push({ boxId: mkBoxId(), initContent: '', kind: 'text' });
      }
      for (const img of imgs) {
        const ast = processor.parse(img);
        const imageNode = (ast.children[0] as ParagraphNode).children[0] as ImageNode;
        boxes.push({ boxId: mkBoxId(), kind: 'image-reified', node: imageNode });
      }
    } else if (section.startsWith('[//]: # (slide)')) {
      boxes.push({ boxId: mkBoxId(), kind: 'slide-divider' });
    } else if (boxes.length > 0 && boxes[boxes.length - 1].kind === 'text') {
      const mergeBox = boxes[boxes.length - 1];
      if (mergeBox.kind === 'text') {
        mergeBox.initContent += '\n\n' + section;
      } else {
        throw Error('Unexpected');
      }
    } else {
      boxes.push({ boxId: mkBoxId(), initContent: section, kind: 'text' });
    }
  }
  return boxes;
};

const splitMarkdownByDoubleNewlines = (input: string): string[] => input.split('\n\n');

const findMarkdownImages = (input: string): string[] => {
  const regex = /\!\[[^\]]*\]\([^\)]+\)(?:\n|$)/g;
  const matches = input.match(regex);
  return matches ? matches.map(match => match.trim()) : [];
};

const findEmptyLink = (input: string): string | null => {
  const regex = /^\[\]\(([^)]+)\)$/;
  const match = regex.exec(input);
  if (match) {
    return match[1];
  } else {
    return null;
  }
};

// --

export default PostBoxesEditor;
