import clsx from 'clsx';
import React from 'react';
import { scrollTo } from 'scroll-js';

interface SectionHeaderTabProps {
  title: string;
  titleSuffix?: React.ReactNode;
  titleClassName?: string;
  right?: React.ReactNode;
  className?: string;
  fullWidth?: boolean;
  onClick?: () => void;
  kb?: {
    selected: boolean;
    scrollTarget?: HTMLDivElement;
  };
}

const SectionHeaderTab = (props: SectionHeaderTabProps) => {
  const divRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (!props.kb?.selected || !divRef.current || !props.kb?.scrollTarget) {
      return;
    }
    const scrollYTarget =
      divRef.current.getBoundingClientRect().top + props.kb.scrollTarget.scrollTop - window.innerHeight / 2 + 100;
    scrollTo(props.kb.scrollTarget, { top: scrollYTarget, easing: 'ease-in-out', duration: 70 });
  }, [props.kb?.selected, props.kb?.scrollTarget, divRef.current]);
  return (
    <div
      ref={divRef}
      className={clsx(
        'tw-flex tw-items-center tw-justify-between tw-gap-x-2 tw-mb-2 tw-text-dark tw-text-[0.9rem] tw-leading-normal',
        props.className ? props.className : null,
        props.fullWidth
          ? 'tw-bg-sky-200 tw-font-semibold tw-py-1 tw-px-4 tw-border-solid tw-border-l-4 tw-border-y-0 tw-border-r-0'
          : null,
        props.kb?.selected ? 'tw-border-l-perpul-light dark:tw-border-l-perpul-dark' : 'tw-border-transparent',
      )}
    >
      <div
        onClick={props.onClick}
        className={clsx(
          'tw-flex tw-flex-initial tw-items-center tw-gap-x-2 sm:tw-gap-x-4 tw-select-none',
          !props.fullWidth
            ? 'tw-bg-sky-200 tw-font-semibold tw-py-1 tw-px-4 tw-border-solid tw-border-l-4 tw-border-y-0 tw-border-r-0'
            : null,
          props.kb?.selected ? 'tw-border-l-perpul-light dark:tw-border-l-perpul-dark' : 'tw-border-transparent',
        )}
      >
        <span className={props.titleClassName}>{props.title}</span>
        {props.titleSuffix ? <>{props.titleSuffix}</> : null}
      </div>
      {props.right ? <>{props.right}</> : null}
    </div>
  );
};

export default SectionHeaderTab;
