import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import * as store from '../state/store';

import ActionButton, { ButtonLabel } from './ActionButton';
import FeedItem from './FeedItem';
import { useAuthedApiClient } from './hooks/useApiClient';
import useApiDo from './hooks/useApiDo';
import { useFeedList } from './hooks/useFeedList';
import useUserMeInternal from './hooks/useUserMeInternal';
import PlusIcon from './icon/PlusIcon';
import SaveIcon from './icon/SaveIcon';
import { useKeyPress } from './KeyPressContext';
import ListGroup from './lib/ListGroup';
import Modal from './lib/Modal';
import Spinner from './lib/Spinner';
import { useModalManager } from './ModalManagerContext';

interface IFeedEntryAddToFeedModalProps {
  entry: api.feed.IFeedEntryReference;
  closeModal: () => void;
  isForeground: boolean;
}

const FeedEntryAddToFeedModal = (props: IFeedEntryAddToFeedModalProps) => {
  const { pushModal } = useModalManager();
  const accountInfo = useUserMeInternal();
  if (!accountInfo) {
    throw Error('Expected logged-in user');
  }
  const apiClient = useAuthedApiClient();
  const keyboardControlsActive = useSelector<store.IAppState, boolean>(state => state.keyboardControlsActive);
  const feedsMap = useSelector<store.IAppState, Map<string, api.feed.IFeedInfo>>(state => state.feeds);
  const { isRefreshing, userFeedIds } = useFeedList({ '.tag': 'id', id: accountInfo.user_id });
  const modalRef = React.useRef<HTMLDivElement | null>(null);

  const filterPredicate = (feed: api.feed.IFeedInfo) => {
    return (
      feed.type['.tag'] !== 'archive' &&
      feed.type['.tag'] !== 'ego' &&
      feed.type['.tag'] !== 'reports' &&
      feed.type['.tag'] !== 'visited' &&
      feed.type['.tag'] !== 'works' &&
      feed.for_viewer.perm.write
    );
  };

  const userFeeds = cfe.ApiData.filter(
    cfe.ApiData.map(userFeedIds, feedId => feedsMap.get(feedId)!),
    feed => filterPredicate(feed),
  );

  const { apiDo: apiFeedEntryAdd, okToast, errToast } = useApiDo(apiClient, apiClient.feedEntryAdd);

  const addToFeed = (feed: api.feed.IFeedInfo) => {
    apiFeedEntryAdd(
      { feed_id: feed.feed_id, url: props.entry.url, via: props.entry.entry_id },
      {
        onResult: res => {
          if (res['.tag'] === 'existing') {
            okToast(`Already recently added to ${cfe.ApiHelpers.getFeedShortName(feed)}`);
          } else if (res['.tag'] === 'new_entry') {
            okToast(`Added to ${cfe.ApiHelpers.getFeedShortName(feed)}`);
          }
          props.closeModal();
        },
        onRouteErr: (err, defaultErrToast) => {
          if (err['.tag'] === 'slow_down') {
            errToast('Slow down', `Try again in ${cfe.Formatter.secondsToHoursMinsSecsStr(err.wait_period)}`);
          } else {
            defaultErrToast();
          }
        },
      },
    );
  };

  const [kbCursorIndex, setKbCursorIndex] = useState(0);

  useKeyPress(
    'n',
    () => {
      // n: Select next feed.
      if (cfe.ApiData.hasData(userFeeds) && kbCursorIndex < userFeeds.data.length - 1) {
        setKbCursorIndex(kbCursorIndex + 1);
      }
    },
    !cfe.ApiData.hasData(userFeeds) || !props.isForeground,
    10,
  );

  useKeyPress(
    'p',
    () => {
      // p: Select prev feed.
      if (kbCursorIndex > 0) {
        setKbCursorIndex(kbCursorIndex - 1);
      }
    },
    !cfe.ApiData.hasData(userFeeds) || !props.isForeground,
    10,
  );

  useKeyPress(
    'Enter',
    () => {
      // enter: Submit
      if (cfe.ApiData.hasData(userFeeds) && kbCursorIndex >= 0 && kbCursorIndex < userFeeds.data.length) {
        addToFeed(userFeeds.data[kbCursorIndex]);
      }
    },
    !cfe.ApiData.hasData(userFeeds),
    10,
  );

  useKeyPress(
    'o',
    () => {
      // o: Submit
      if (cfe.ApiData.hasData(userFeeds) && kbCursorIndex >= 0 && kbCursorIndex < userFeeds.data.length) {
        addToFeed(userFeeds.data[kbCursorIndex]);
      }
    },
    !cfe.ApiData.hasData(userFeeds),
    10,
  );
  useKeyPress(
    'O',
    () => {
      // O: Go to "add with note"
      if (cfe.ApiData.hasData(userFeeds) && kbCursorIndex >= 0 && kbCursorIndex < userFeeds.data.length) {
        pushModal({ kind: 'add', feed: userFeeds.data[kbCursorIndex], src: props.entry });
      }
    },
    !cfe.ApiData.hasData(userFeeds),
    10,
  );
  useKeyPress(
    ['u', 'Escape', '3'],
    () => {
      props.closeModal();
    },
    !props.isForeground,
    10,
  );
  const title = cfe.ApiHelpers.getEntryTitle(props.entry);
  return (
    <Modal.Container ref={modalRef} show={props.isForeground} close={() => props.closeModal()}>
      <Modal.Header>
        <Modal.Heading1>
          <SaveIcon size="1.3rem" offsetUp /> Save to Library
        </Modal.Heading1>
        {title ? <div className="tw-text-muted">{title}</div> : null}
      </Modal.Header>
      <Modal.Body>
        <ListGroup>
          {cfe.ApiData.hasData(userFeeds)
            ? userFeeds.data.map((feed, index) => (
                <FeedItem
                  key={feed.feed_id}
                  feed={feed}
                  onClickOverride={() => addToFeed(feed)}
                  kbActive={keyboardControlsActive && index === kbCursorIndex}
                  rightEle={
                    <ActionButton
                      sm
                      title="With Note"
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        pushModal({ kind: 'add', feed, src: props.entry });
                      }}
                    >
                      <PlusIcon size="0.9rem" />
                      <ButtonLabel text="Note" xsHide={false} />
                    </ActionButton>
                  }
                  hideBlurb={true}
                  hideFollowBtn={true}
                  hideFollowers={true}
                  hidePublisher={true}
                  enableWindowScroll={true}
                  scrollTarget={modalRef.current ?? undefined}
                />
              ))
            : null}
          <Spinner.Presence>
            {isRefreshing || cfe.ApiData.isLoading(userFeeds) ? (
              <div>
                <Spinner />
              </div>
            ) : null}
          </Spinner.Presence>
        </ListGroup>
      </Modal.Body>
    </Modal.Container>
  );
};

export default FeedEntryAddToFeedModal;
